import {
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  TextField,
  EditButton,
  DateField,
  Resource,
  Show,
  Filter,
  Datagrid,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";

const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
  </Filter>
);

const ArticleHazardousList = (props) => {
  return (
    <List {...props} filters={Filters(props)}>
      <div style={{ marginTop: "15px" }}>
        <Datagrid rowClick="show">
          <ReferenceField source="articleId" reference="articles">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField label="Reference" source="articleId" reference="articles">
            <TextField source="reference" />
          </ReferenceField>
          <ReferenceField
            source="hazardousSubstancesId"
            reference="hazardousSubstances"
          >
            <TextField source="shortName" />
          </ReferenceField>
          <DateField source="createdAt" label="Creation date" />
          <DateField source="updatedAt" label="Update date" />
          <EditButton />
        </Datagrid>
      </div>
    </List>
  );
};

const ShowArticleHazardous = (props) => {
  return (
    <Show {...props}>
      <SimpleForm>
        <ReferenceField source="articleId" reference="articles">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Reference" source="articleId" reference="articles">
            <TextField source="reference" />
          </ReferenceField>
        <ReferenceField
          source="hazardousSubstancesId"
          reference="hazardousSubstances"
        >
          <TextField source="shortName" />
        </ReferenceField>
        <DateField source="createdAt" label="Creation date" />
        <DateField source="updatedAt" label="Update date" />
      </SimpleForm>
    </Show>
  );
};

const CreateArticleHazardous = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <ReferenceInput source="articleId" reference="articles">
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          source="hazardousSubstancesId"
          reference="hazardousSubstances"
        >
          <AutocompleteInput optionText="shortName" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

const EditArticleHazardous = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <ReferenceInput source="articleId" reference="articles">
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          source="hazardousSubstancesId"
          reference="hazardousSubstances"
        >
          <AutocompleteInput optionText="shortName" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};

const articleHazardousResource = () => (
  <Resource
    name="articleHazardous"
    options={{ label: "Article hazardous", menuGroup: "Articles" }}
    list={ArticleHazardousList}
    create={CreateArticleHazardous}
    edit={EditArticleHazardous}
    show={ShowArticleHazardous}
  />
);

export default articleHazardousResource;
