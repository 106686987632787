import { hostname } from "./api";
import jwt_decode from "jwt-decode";

let refreshTokenTimeout;
let isRefreshing = false;
let pendingRequests = [];

const authProvider = (tokenOps) => {
  const startRefreshTokenTimer = (accessToken) => {
    const jwtToken = jwt_decode(accessToken);
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - 60 * 1000;

    refreshTokenTimeout = setTimeout(refreshAccessToken, timeout);
  };

  const refreshAccessToken = async () => {
    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        pendingRequests.push({ resolve, reject });
      });
    }

    isRefreshing = true;
    const refreshToken = tokenOps.root?.refreshToken;
    if (!refreshToken) {
      isRefreshing = false;
      return Promise.reject(new Error("No refreshToken available"));
    }

    try {
      const response = await fetch(`${hostname}/auth/refresh`, {
        method: "POST",
        body: JSON.stringify({ refreshToken }),
        headers: new Headers({ "Content-Type": "application/json" }),
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const auth = await response.json();

      tokenOps.setRootToken(auth);
      startRefreshTokenTimer(auth.accessToken);

      pendingRequests.forEach((req) => req.resolve(auth.accessToken));
      pendingRequests = [];
      isRefreshing = false;
      return auth.accessToken;
    } catch (error) {
      pendingRequests.forEach((req) => req.reject(error));
      pendingRequests = [];
      isRefreshing = false;
      tokenOps.removeRootToken();
      return Promise.reject(error);
    }
  };

  const fetchWithToken = async (url, options = {}) => {
    try {
      const accessToken = await checkAndRenewToken();
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await fetch(url, options);

      if (response.status === 403) {
        await refreshAccessToken();
        const retryOptions = {
          ...options,
          headers: {
            ...options.headers,
            Authorization: `Bearer ${tokenOps.account.accessToken}`,
          },
        };
        return fetch(url, retryOptions);
      }

      return response;
    } catch (error) {
      console.error("[AuthProvider] Error en fetchWithToken:", error);
      throw error;
    }
  };

  const checkAndRenewToken = async () => {
    const accessToken = tokenOps.account?.accessToken;
    if (!accessToken) {
      return Promise.reject(new Error("No accessToken found"));
    }

    const token = jwt_decode(accessToken);
    const currentTime = Date.now();
    const expirationTime = token.exp * 1000;

    if (currentTime >= expirationTime - 60 * 1000) {
      return refreshAccessToken();
    }

    return accessToken;
  };

  return {
    login: async ({ username, password, remember = false }) => {
      try {
        const response = await fetch(`${hostname}/auth/access`, {
          method: "POST",
          body: JSON.stringify({ email: username, password, remember }),
          headers: new Headers({ "Content-Type": "application/json" }),
        });

        if (!response.ok) {
          throw new Error(response.statusText);
        }

        const auth = await response.json();
        tokenOps.setRootToken(auth);

        const decodedToken = jwt_decode(auth.accessToken);
        if (!decodedToken.roles.includes("ADMIN")) {
          throw new Error("Wrong roles");
        }

        startRefreshTokenTimer(auth.accessToken);
      } catch (error) {
        console.error("[AuthProvider] Error en login:", error);
        throw error;
      }
    },

    fetchWithToken, // Usar este método para solicitudes protegidas

    checkAuth: async () => {
      const accessToken = tokenOps.account?.accessToken;
      if (!accessToken) {
        return Promise.reject();
      }

      const token = jwt_decode(accessToken);
      const currentTime = Date.now();
      const expirationTime = token.exp * 1000;

      if (currentTime >= expirationTime - 60 * 1000) {
        return refreshAccessToken();
      }

      return Promise.resolve();
    },

    getPermissions: () => {
      const roles = tokenOps.root?.data?.roles;
      if (!roles) {
        return Promise.reject(new Error("No roles available"));
      }
      return Promise.resolve({ roles });
    },

    checkError: async (error) => {
      const status = error.status;

      if (status === 401 || status === 403) {
        try {
          await refreshAccessToken();
        } catch {
          tokenOps.removeRootToken();
          return Promise.reject();
        }
      }
      return Promise.resolve();
    },

    logout: () => {
      clearTimeout(refreshTokenTimeout);
      tokenOps.removeRootToken();
      return Promise.resolve();
    },
  };
};

export default authProvider;
