const Statuses = [
    {
        id: "Ready",
        name: "Ready",
        color: "#517801"
    },
    {
        id: "Draft",
        name: "Draft",
        color: "#959884"
    },
    {
        id: "Review Pending",
        name: "Review Pending",
        color: "#e0a75e"
    },
    {
        id: "Work in Progress",
        name: "Work in Progress",
        color: "#b34d49ff"
    },
];

export default Statuses;