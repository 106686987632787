import {
    Create,
    DateField,
    Edit,
    EditButton,
    List, NumberInput,
    Resource,
    Show,
    SimpleForm,
    SelectInput,
    TextField,
    SingleFieldList,
    ReferenceArrayInput,
    ChipField,
    TextInput,
    ArrayField,
    NumberField, CheckboxGroupInput, Datagrid } from "react-admin";
import React from "react";
import statuses from "../moqup/Statuses";
import {Filters} from "./BasicResource";

const GlossaryList = (props) => {


    return (
        <List {...props} filters={Filters(props)}>
            <div style={{marginTop: '15px'}}>
                <Datagrid rowClick="show">
                    <TextField source="title"/>
                    <TextField source="body"/>
                    <TextField source="version"/>
                    <TextField source="status"/>
                    <ArrayField label="Related Terms" source="children">
                        <SingleFieldList>
                            <ChipField  source="title"/>
                        </SingleFieldList>
                    </ArrayField>
                    <DateField source="createdAt" label="Creation date"/>
                    <DateField source="updatedAt" label="Update date"/>
                    <EditButton/>
                </Datagrid>
            </div>
        </List>
    );
};


const EditGlossary = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput source="title" required={true}/>
                <TextInput source="body" required={true}/>
                <NumberInput source="version"/>
                <SelectInput choices={statuses} source="status"/>
                <ReferenceArrayInput label="Related Terms" source="children" reference="glossary">
                    <CheckboxGroupInput source="id" optionValue="id" optionText="title"/>
                </ReferenceArrayInput>

            </SimpleForm>
        </Edit>
    );
};

const CreateGlossary = (props) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source="title" required={true}/>
                <TextInput source="body" required={true}/>
                <TextInput source="version"/>
                <SelectInput choices={statuses} source="status"/>
                <ReferenceArrayInput label="Related Terms" source="children" reference="glossary">
                    <CheckboxGroupInput optionValue="id" optionText="title"/>
                </ReferenceArrayInput>
            </SimpleForm>
        </Create>
    );
};


const ShowGlossary = (props) => {
    return (
        <Show {...props}>
            <SimpleForm>
                <TextField source="title"/>
                <TextField source="body"/>
                <NumberField source="version"/>
                <TextField source="status"/>
                <ArrayField label="Related Terms" source="children">
                    <SingleFieldList>
                        <ChipField  source="title"/>
                    </SingleFieldList>
                </ArrayField>
            </SimpleForm>
        </Show>
    );
};

const glossaryResource = () => <Resource
    name="glossary"
    options={{label: 'Glossary', menuGroup: 'Glossary'}}
    list={GlossaryList}
    edit={EditGlossary}
    create={CreateGlossary}
    show={ShowGlossary}/>;

export default glossaryResource;

