import {
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  TextField,
  EditButton,
  DateField,
  Resource,
  Show,
  Filter,
  ImageField,
  Datagrid } from "react-admin";


const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
    <TextInput label="Category" source="category" />
    <TextInput label="Unit Type" source="unitType" />

  </Filter>
);

const EquivalencesList = (props) => {
  return (
    <List {...props} filters={Filters(props)}>
      <div style={{ marginTop: '15px' }}>
        <Datagrid rowClick="show">
          <TextField source="name" />
          <TextField source="keyName" />
          <TextField source="id" />
          <TextField source="category" />
          <ImageField source="icon" />
          <TextField source="unit" />
          <TextField source="unitType" />
          <TextField source="amount" />
          <TextField source="description" />
          <TextField source="primarySource" />
          <DateField source="createdAt" label="Creation date" />
          <DateField source="updatedAt" label="Update date" />
          <EditButton />
        </Datagrid>
      </div>
    </List>
  );
};

const ShowEquivalences = (props) => {
  return (
    <Show {...props}>
      <SimpleForm>
        <TextField source="name" />
        <TextField source="keyName" />
        <TextField source="id" />
        <TextField source="category" />
        <ImageField source="icon" />
        <TextField source="unit" />
        <TextField source="unitType" />
        <TextField source="amount" />
        <TextField source="description" />
        <TextField source="primarySource" />
        <DateField source="createdAt" label="Creation date" />
        <DateField source="updatedAt" label="Update date" />
      </SimpleForm>
    </Show>
  );
};

const CreateEquivalences = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="category" />
        <TextInput source="icon" />
        <TextInput source="unit" />
        <TextInput source="unitType" />
        <TextInput source="amount" />
        <TextInput source="description" />
        <TextInput source="primarySource" />
      </SimpleForm>
    </Create>
  );
};

const EditEquivalences = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="category" />
        <TextInput source="icon" />
        <TextInput source="unit" />
        <TextInput source="unitType" />
        <TextInput source="amount" />
        <TextInput source="description" />
        <TextInput source="primarySource" />
      </SimpleForm>
    </Edit>
  );
};


const equivalencesResource = () => <Resource
  name="equivalences"
  options={{ label: 'Equivalences', menuGroup: 'Bcome' }}
  list={EquivalencesList}
  create={CreateEquivalences}
  edit={EditEquivalences}
  show={ShowEquivalences} />;

export default equivalencesResource;
