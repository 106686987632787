
import { hostname } from "./api";
import * as fetchUtils from "ra-core/lib/dataProvider/fetch";
import { useMemo } from "react";
import { CondOperator, RequestQueryBuilder } from '@nestjsx/crud-request';
import omitBy from 'lodash.omitby';
import { stringify } from 'query-string';
import { validateSupplier } from "../utils/utils";

const fetchJson = token => (url, options = {}) => {
  options.user = {
    authenticated: true,
    token: 'Bearer ' + token,
  };
  return fetchUtils.fetchJson(url, options);
};

const countDiff = (o1, o2) =>
  omitBy(o1, (v, k) => o2[k] === v);

const enumTypes = {
  process: ["tier"],
  views: ["product"]
}

const composeFilter = (paramsFilter, resource) => {
  const flatFilter = fetchUtils.flattenObject(paramsFilter);
  return Object.keys(flatFilter).map((key) => {
    const splitKey = key.split('||');

    let field = splitKey[0];
    let ops = splitKey[1];
    if (!ops) {
      if (Array.isArray(flatFilter[key])) {
        ops = CondOperator.IN;
      } else if (enumTypes[resource] && enumTypes[resource].includes(key)) {
        ops = CondOperator.EQUALS
      }
      else if (typeof flatFilter[key] === 'boolean'
        || typeof flatFilter[key] === 'number'
        || (typeof flatFilter[key] === 'string' && flatFilter[key].match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/))) {
        ops = CondOperator.EQUALS;
      } else {
        ops = CondOperator.CONTAINS;
      }
    }

    if (field.startsWith('_') && field.includes('.')) {
      field = field.split(/\.(.+)/)[1];
    }
    return { field, operator: ops, value: flatFilter[key] };
  });
};

const composeQueryParams = (queryParams = {}) => {
  return stringify(fetchUtils.flattenObject(queryParams), { skipNull: true });
}

const mergeEncodedQueries = (...encodedQueries) => encodedQueries.map((query) => query).join('&')

const joinResources = {
  'process': 'preProcesses',
  'rawMaterialMaterialProcesses': 'processes'
};

const crudProvider = (apiUrl, httpClient = fetchUtils.fetchJson, tokenOps) => ({
  getList: (resource, params) => {


    const { page, perPage } = params.pagination;
    let { q: queryParams, $OR: orFilter, ...filter } = params.filter || {};

    if (resource === 'filterAccountBestPractices') {
      queryParams = {
        ...queryParams,
        'accountId': tokenOps.account?.data.accountId
      }
    }

    const encodedQueryParams = composeQueryParams(queryParams)
    const encodedQueryFilter = RequestQueryBuilder.create({
      filter: composeFilter(filter, resource),
      or: composeFilter(orFilter || {}, resource)
    })
      .setLimit(perPage)
      .setPage(page)
      .sortBy(params.sort)
      .setOffset((page - 1) * perPage)
      .query();

    const query = mergeEncodedQueries(encodedQueryParams, encodedQueryFilter);
    const url = `${apiUrl}/${resource}?${query}`;

    return httpClient(url).then(({ json }) => ({
      data: json.data,
      total: json.total,
    }));
  },

  getOne: (resource, params) => {
    const query = {}
    if (Object.keys(joinResources).includes(resource)) {
      query.join = joinResources[resource]
    }

    return httpClient(`${apiUrl}/${resource}/${params.id}?${composeQueryParams(query)}`).then(({ json }) => ({
      data: json,
    }))
  },

  getMany: (resource, params) => {
    const query = RequestQueryBuilder.create()
      .setFilter({
        field: 'id',
        operator: CondOperator.IN,
        value: `${params.ids}`,
      })
      .query();

    const url = `${apiUrl}/${resource}?${query}`;

    return httpClient(url).then(({ json }) => ({ data: json.data || validateSupplier(url) ? json[0] : json }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { q: queryParams, ...otherFilters } = params.filter || {}
    const filter = composeFilter(otherFilters, resource);

    filter.push({
      field: params.target,
      operator: CondOperator.EQUALS,
      value: params.id,
    });

    const encodedQueryParams = composeQueryParams(queryParams)
    const encodedQueryFilter = RequestQueryBuilder.create({
      filter
    })
      .sortBy(params.sort)
      .setLimit(perPage)
      .setOffset((page - 1) * perPage)
      .query();

    const query = mergeEncodedQueries(encodedQueryParams, encodedQueryFilter);

    const url = `${apiUrl}/${resource}?${query}`;

    return httpClient(url).then(({ json }) => ({
      data: json.data,
      total: json.total,
    }));
  },

  update: (resource, params) => {
    // no need to send all fields, only updated fields are enough
    const data = countDiff(params.data, params.previousData);
    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PATCH',
      body: JSON.stringify(data),
    }).then(({ json }) => ({ data: json }));
  },

  updateMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'PUT',
          body: JSON.stringify(params.data),
        }),
      ),
    ).then((responses) => ({
      data: responses.map(({ json }) => json),
    })),

  create: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    })),

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: { ...json, id: params.id } })),

  deleteMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'DELETE',
        }),
      ),
    ).then((responses) => ({ data: responses.map(({ json }) => json) })),
});




const useDataProvider = (tokenOps) => {
  return useMemo(() => {
    return crudProvider(hostname, fetchJson(tokenOps.account?.accessToken), tokenOps);
  }, [tokenOps.account?.accessToken]);
}

export default useDataProvider;
