import {
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  TextField,
  EditButton,
  DateField,
  Resource,
  Show,
  ReferenceField,
  SelectInput,
  Filter,
  ReferenceInput,
Datagrid } from "react-admin";


const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="material.name" alwaysOn />
  </Filter>
);

const MaterialCertificateList = (props) => {
  return (
    <List {...props} filters={Filters(props)}>
      <div style={{marginTop: '15px'}}>
        <Datagrid rowClick="show">
          <TextField source="id" />
          <ReferenceField label="Material" source="materialId" reference="material">
            <TextField source="materialName" />
          </ReferenceField>
          <ReferenceField label="Certificates" source="certificateId" reference="certificates">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField label="Account" source="accountId" reference="accounts">
            <TextField source="name" />
          </ReferenceField>
          <TextField source="status" />
          <DateField source="createdAt" label="Creation date" />
          <DateField source="updatedAt" label="Update date" />
          <EditButton />
        </Datagrid>
      </div>
    </List>
  );
};

const ShowMaterialCertificate = (props) => {
  return (
    <Show {...props}>
      <SimpleForm>
        <TextField source="id" />
        <ReferenceField label="Material" source="materialId" reference="material">
          <TextField source="materialName" />
        </ReferenceField>
        <ReferenceField label="Certificates" source="certificateId" reference="certificates">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Account" source="accountId" reference="accounts">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="status" />
        <DateField source="createdAt" label="Creation date" />
        <DateField source="updatedAt" label="Update date" />
      </SimpleForm>
    </Show>
  );
};

const CreateMaterialCertificate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="id" />
        <ReferenceInput label="Material" source="materialId" reference="material">
          <SelectInput optionText="materialName" required={true} />
        </ReferenceInput>
        <ReferenceInput label="Certificates" source="certificateId" reference="certificates">
          <SelectInput optionText="name" required={true} />
        </ReferenceInput>
        <TextInput source="status" />
      </SimpleForm>
    </Create>
  );
};

const EditMaterialCertificate = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
      <TextInput source="id" />
        <ReferenceInput label="Material" source="materialId" reference="material">
          <SelectInput optionText="materialName" required={true} />
        </ReferenceInput>
        <ReferenceInput label="Certificates" source="certificateId" reference="certificates">
          <SelectInput optionText="name" required={true} />
        </ReferenceInput>
        <TextInput source="status" />
      </SimpleForm>
    </Edit>
  );
};


const materialCertificateResource = () => <Resource
  name="materialCertificate"
  options={{ label: 'Material certificates', menuGroup: 'Materials'}}
  list={MaterialCertificateList}
  create={CreateMaterialCertificate}
  edit={EditMaterialCertificate}
  show={ShowMaterialCertificate} />;

export default materialCertificateResource;
