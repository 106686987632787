import {
    List,
    Create,
    Edit,
    SimpleForm,
    TextInput,
    TextField,
    EditButton,
    DateField,
    Resource,
    Filter,
    Show,
  Datagrid,
  ReferenceField,
  BooleanField,
  ReferenceInput,
  BooleanInput,
  SelectInput,
  AutocompleteInput,
  useRecordContext,
  useGetOne
} from "react-admin";


  const Filters = (props) => (
    <Filter {...props}>
      <TextInput label="Search" source="name" alwaysOn />
    </Filter>
  );

  const CustomReferenceField = ({ source, reference, defaultValue, ...rest }) => {
    const record = useRecordContext();

    // Fetch the reference entity directly to check its content
    const { data, isLoading } = useGetOne(reference, { id: record[source] });

    // Show loading state if the data is still being fetched
    if (isLoading) {
        return <span>Loading...</span>;
    }

    // Check if data exists and is not empty, otherwise show default value
    if (!data || (Array.isArray(data) && data.length === 0)) {
        return <span>{defaultValue}</span>;
    }

    return (
        <ReferenceField source={source} reference={reference} {...rest}>
            <TextField source="supplierName" />
        </ReferenceField>
    );
};

  const RetailPackagingList = (props) => {
    return (
      <List {...props} filters={Filters(props)}>
        <div style={{marginTop: '15px'}}>
          <Datagrid rowClick="show">
            <TextField source="name" />
            <TextField source="id" />
            <TextField source="reference" />
            <ReferenceField source="processId" reference="process">
                <TextField source="name" />
            </ReferenceField>
            <CustomReferenceField
                source="supplierId"
                reference="supplier"
                defaultValue="Unknown"
            />
            <BooleanField source="takeBack" />
            <ReferenceField source="hazardousSubstancesId" reference="hazardousSubstances" emptyText="Not applicable">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="projectId" reference="projects">
                <TextField source="name" />
            </ReferenceField>
            <DateField source="createdAt" label="Creation date" />
            <DateField source="updatedAt" label="Update date" />
            <EditButton />
          </Datagrid>
        </div>
      </List>
    );
  };

  const ShowRetailPackaging = (props) => {
    return (
      <Show {...props}>
        <SimpleForm>
            <TextField source="name" />
            <TextField source="id" />
            <TextField source="reference" />
            <ReferenceField source="processId" reference="process">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="supplierId" reference="supplier">
                <TextField source="supplierName" />
            </ReferenceField>
            <BooleanField source="takeBack" />
            <ReferenceField source="hazardousSubstancesId" reference="hazardousSubstances">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="projectId" reference="projects">
                <TextField source="name" />
            </ReferenceField>
            <DateField source="createdAt" label="Creation date" />
            <DateField source="updatedAt" label="Update date" />
        </SimpleForm>
      </Show>
    );
  };

  const CreateRetailPackaging = (props) => {
    return (
      <Create {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="reference" />
            <ReferenceInput source="processId" reference="process" filter={{ categoryId: 'c293b629-8c56-462f-bcae-66438a064ac8'}}>
                <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput source="supplierId" reference="supplier">
                <SelectInput optionText="supplierName" />
            </ReferenceInput>
            <BooleanInput source="takeBack" defaultValue={false} />
            <ReferenceInput source="hazardousSubstancesId" reference="hazardousSubstances">
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput source="projectId" reference="projects">
                <SelectInput optionText="name" />
            </ReferenceInput>
        </SimpleForm>
      </Create>
    );
  };

  const EditRetailPackaging = (props) => {
    return (
      <Edit {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="reference" />
            <ReferenceInput source="processId" reference="process" filter={{ categoryId: 'c293b629-8c56-462f-bcae-66438a064ac8'}}>
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput source="supplierId" reference="supplier">
                <AutocompleteInput optionText="supplierName" />
            </ReferenceInput>
            <BooleanInput source="takeBack" defaultValue={false} />
            <ReferenceInput source="hazardousSubstancesId" reference="hazardousSubstances">
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput source="projectId" reference="projects">
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
        </SimpleForm>
      </Edit>
    );
  };


  const retailPackagingResource = () => <Resource
    name="retailPackaging"
    options={{ label: 'Retail packaging', menuGroup: 'Packaging'}}
    list={RetailPackagingList}
    create={CreateRetailPackaging}
    edit={EditRetailPackaging}
    show={ShowRetailPackaging} />;

  export default retailPackagingResource;
