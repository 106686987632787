import React from "react";
import "./toggle.css";

const Toggle = ({
	onChange,
	checked,
	disabled = false,
	size = "0.2",
	className = "",
	styles,
	ranId = `${Math.random()}`,
}) => {
	const onToggleCHange = () => {
		if (onChange && !disabled) {
			onChange(!checked);
		}
	};
	return (
		<>
			<input
				type="checkbox"
				id={`toggle-${ranId}`}
				className="toggle-input"
				onChange={onToggleCHange}
				checked={checked}
			/>
			<div
				style={styles}
				className={`toggle-div ${
					disabled ? "toggle-disabled" : ""
				} ${className}`}
			>
				<label
					className={`toggle-label ${
						disabled ? "toggle-label-disabled" : ""
					}`}
					htmlFor={`toggle-${ranId}`}
				></label>
			</div>
		</>
	);
};

export default Toggle;
