import {
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  TextField,
  EditButton,
  DateField,
  Resource,
  Filter,
  Show,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  Datagrid } from "react-admin";


const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
  </Filter>
);

const OriginsList = (props) => {
  return (
    <List {...props} filters={Filters(props)}>
      <div style={{ marginTop: '15px' }}>
        <Datagrid rowClick="show">
          <TextField source="name" />
          <TextField source="fr_translation" />
          <TextField source="id" />
          <ReferenceField label="Parent origin" source="parentOriginId" reference="origins">
            <TextField source="name" />
          </ReferenceField>
          <TextField source="geolocLatitude" />
          <TextField source="geolocLongitude" />
          <TextField source="abbreviation" />
          <DateField source="createdAt" label="Creation date" />
          <DateField source="updatedAt" label="Update date" />
          <EditButton />
        </Datagrid>
      </div>
    </List>
  );
};

const ShowOrigins = (props) => {
  return (
    <Show {...props}>
      <SimpleForm>
        <TextField source="name" />
        <TextField source="fr_translation" />
        <TextField source="id" />
        <ReferenceField label="Parent origin" source="parentOriginId" reference="origins">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="geolocLatitude" />
        <TextField source="geolocLongitude" />
        <TextField source="abbreviation" />
        <DateField source="createdAt" label="Creation date" />
        <DateField source="updatedAt" label="Update date" />
      </SimpleForm>
    </Show>
  );
};

const CreateOrigins = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="fr_translation" />
        <ReferenceInput label="Parent origin" source="parentOriginId" reference="origins" filterToQuery={searchText => ({ name: searchText })}>
          <AutocompleteInput source="name" />
        </ReferenceInput>
        <TextInput source="geolocLatitude" />
        <TextInput source="geolocLongitude" />
        <TextInput source="abbreviation" />
      </SimpleForm>
    </Create>
  );
};

const EditOrigins = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="fr_translation" />
        <ReferenceInput label="Parent origin" source="parentOriginId" reference="origins" filterToQuery={searchText => ({ name: searchText })}>
          <AutocompleteInput source="name" />
        </ReferenceInput>
        <TextInput source="geolocLatitude" />
        <TextInput source="geolocLongitude" />
        <TextInput source="abbreviation" />
      </SimpleForm>
    </Edit>
  );
};


const originsResource = () => <Resource
  name="origins"
  options={{ label: 'Origins', menuGroup: 'Bcome' }}
  list={OriginsList}
  create={CreateOrigins}
  edit={EditOrigins}
  show={ShowOrigins} />;

export default originsResource;
