import {
  List,
  SimpleForm,
  TextInput,
  TextField,
  DateField,
  Resource,
  Filter,
  Show,
  Datagrid,
  useRecordContext,
  NumberField,
} from "react-admin";

import React from "react";
import { numberFiedDefaultOptions } from "../consts";

const FormattedNumberField = ({ source, label }) => {
  const record = useRecordContext();
  const value = record?.[source];

  const formattedValue = value != null
    ? value.toLocaleString("de-DE", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) 
    : "";

  return (
    <div className="formatted-number-field">
      {label ? <span className="label">{label}</span> : null}
      <span className="value">{formattedValue}</span>
    </div>
  );
};


const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
  </Filter>
);

const DigitalizationDataList = (props) => {
  return (
    <List {...props} filters={Filters(props)}>
      <div style={{ marginTop: "15px" }}>
        <Datagrid rowClick="show">
          <TextField source="name" />
          <TextField source="project_name" />
          <TextField source="reference" />
          <TextField source="ecom_reference" />
          <TextField source="version" />
          <FormattedNumberField source="traceability_total_suppliers_traced_percentage" />
          <TextField source="traceability_process" />
          <TextField source="traceability_suppliers" />
          <FormattedNumberField source="traceability_km" />
          <TextField source="article_process_manufacturing_origin" />
          <TextField source="article_process_finishing_origin_unique" />
          <TextField source="article_process_material_processing_origin_unique" />
          <FormattedNumberField source="water" />
          <FormattedNumberField source="eutrophication" />
          <FormattedNumberField source="climate_change" />
          <FormattedNumberField source="fossil_fuels" />
          <FormattedNumberField source="savings_water_relative" />
          <FormattedNumberField source="savings_climate_change_relative" />
          <FormattedNumberField source="savings_eutrophication_relative" />
          <FormattedNumberField source="savings_fossil_fuels_relative" />
          <FormattedNumberField source="equivalences_water_relative" />
          <FormattedNumberField source="equivalences_climate_change_relative" />
          <FormattedNumberField source="equivalences_eutrophication_relative" />
          <FormattedNumberField source="equivalences_fossil_fuels_relative" />
          <FormattedNumberField source="mci_a" />
          <FormattedNumberField source="recycled_a" />
          <FormattedNumberField source="tu_waste_a" />
          <TextField source="utility_percentage_a" />
          <TextField source="planet_score" />
          <FormattedNumberField source="planet_qualification" />
          <TextField source="people_score" />
          <FormattedNumberField source="people_qualification" />
          <TextField source="transparency_score" />
          <FormattedNumberField source="transparency_qualification" />
          <TextField source="circularity_score" />
          <FormattedNumberField source="circularity_qualification" />
          <TextField source="functional_unit" />

          <TextField source="article_group" label="Article group" />
          <TextField
            source="article_process_manufacturing_origin_fr"
            label="Traceability - Manufacturing"
          />
          <TextField
            source="article_process_material_processing_origin_unique_fr"
            label="Traceability - Material processing"
          />
          <TextField
            source="article_process_finishing_origin_unique_fr"
            label="Traceability - Finishing"
          />
          <TextField
            source="article_process_extraction_polymer_share_fr"
            label="Microplastics"
          />
          <TextField
            source="article_process_extraction_recycled_share_fr"
            label="Recycled content"
          />
          <TextField
            source="article_hazardous_substance_fr"
            label="Hazardous substances"
          />

          <NumberField options={numberFiedDefaultOptions} source="wc_u_a" label="Unitary impact for Water consumption" />
          <NumberField options={numberFiedDefaultOptions} source="wc_ue_a" label="Unitary equivalence for Water consumption" />
          <NumberField options={numberFiedDefaultOptions} source="wc_r_sd" label="Relative Savings/Debts impact for Water consumption" />

          <DateField source="createdAt" label="Creation date" />
          <DateField source="updatedAt" label="Update date" />
        </Datagrid>
      </div>
    </List>
  );
};

const ShowDigitalizationData = (props) => {
  return (
    <Show {...props}>
      <SimpleForm>
        <TextField source="name" />
        <TextField source="project_name" />
        <TextField source="reference" />
        <TextField source="ecom_reference" />
        <TextField source="version" />
        <FormattedNumberField
          source="traceability_total_suppliers_traced_percentage"
          label={"Traceability Total Suppliers Traced Percentage"}
        />
        <TextField source="traceability_process" />
        <TextField source="traceability_suppliers" />
        <FormattedNumberField
          source="traceability_km"
          label={"Traceability Km"}
        />
        <TextField source="article_process_manufacturing_origin" />
        <TextField source="article_process_finishing_origin_unique" />
        <TextField source="article_process_material_processing_origin_unique" />
        <FormattedNumberField source="water" label="Water" />
        <FormattedNumberField source="eutrophication" label="Eutrophication" />
        <FormattedNumberField source="climate_change" label="Climate Change" />
        <FormattedNumberField source="fossil_fuels" label="Fossil Fuels" />
        <FormattedNumberField
          source="savings_water_relative"
          label="Savings Water Relative"
        />
        <FormattedNumberField
          source="savings_climate_change_relative"
          label="Savings Climate Change Relative"
        />
        <FormattedNumberField
          source="savings_eutrophication_relative"
          label="Savings Eutrophication Relative"
        />
        <FormattedNumberField
          source="savings_fossil_fuels_relative"
          label="Savings Fossil Fuels Relative"
        />
        <FormattedNumberField
          source="equivalences_water_relative"
          label="Equivalences Water Relative"
        />
        <FormattedNumberField
          source="equivalences_climate_change_relative"
          label="Equivalences Climate Change Relative"
        />
        <FormattedNumberField
          source="equivalences_eutrophication_relative"
          label="Equivalences Eutrophication Relative"
        />
        <FormattedNumberField
          source="equivalences_fossil_fuels_relative"
          label="Equivalences Fossil Fuels Relative"
        />
        <FormattedNumberField source="mci_a" label="Mci A" />
        <FormattedNumberField source="recycled_a" label="Recycled A" />
        <FormattedNumberField source="tu_waste_a" label="Tu Waste A" />
        <TextField source="utility_percentage_a" />
        <TextField source="planet_score" />
        <FormattedNumberField
          source="planet_qualification"
          label="Planet Qualification"
        />
        <TextField source="people_score" />
        <FormattedNumberField
          source="people_qualification"
          label="People Qualification"
        />
        <TextField source="transparency_score" />
        <FormattedNumberField
          source="transparency_qualification"
          label="Transparency Qualification"
        />
        <TextField source="circularity_score" />
        <FormattedNumberField
          source="circularity_qualification"
          label="Circularity Qualification"
        />
        <TextField source="functional_unit" />

        <TextField source="article_group" label="Article group" />
        <TextField
          source="article_process_manufacturing_origin_fr"
          label="Traceability - Manufacturing"
        />
        <TextField
          source="article_process_material_processing_origin_unique_fr"
          label="Traceability - Material processing"
        />
        <TextField
          source="article_process_finishing_origin_unique_fr"
          label="Traceability - Finishing"
        />
        <TextField
          source="article_process_extraction_polymer_share_fr"
          label="Microplastics"
        />
        <TextField
          source="article_process_extraction_recycled_share_fr"
          label="Recycled content"
        />
        <TextField
          source="article_hazardous_substance_fr"
          label="Hazardous substances"
        />

        <NumberField options={numberFiedDefaultOptions} source="wc_u_a" label="Unitary impact for Water consumption" />
        <NumberField options={numberFiedDefaultOptions} source="wc_ue_a" label="Unitary equivalence for Water consumption" />
        <NumberField options={numberFiedDefaultOptions} source="wc_r_sd" label="Relative Savings/Debts impact for Water consumption" />

        <DateField source="createdAt" label="Creation date" />
        <DateField source="updatedAt" label="Update date" />
      </SimpleForm>
    </Show>
  );
};

const digitalizationDataResource = () => (
  <Resource
    name="articlesummary"
    options={{ label: "Digitization data", menuGroup: "Articles" }}
    list={DigitalizationDataList}
    show={ShowDigitalizationData}
  />
);

export default digitalizationDataResource;
