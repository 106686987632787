import {
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  TextField,
  EditButton,
  useRecordContext,
  DateField,
  Resource,
  TabbedShowLayout,
  Tab,
  Show,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  Filter, Datagrid,
  TabbedForm,
  FormTab,
  ArrayInput,
  SimpleFormIterator,
  AutocompleteInput, BooleanInput, BooleanField, NumberField, NumberInput } from "react-admin";
import { FUNCTIONAL_UNITS, tierChoices } from "../consts";


const Filters = (props) => (

  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
    <ReferenceInput label="Category" source="categoryId" reference="lcaCategory" filterToQuery={searchText => ({ name: searchText })}>
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput label="Group" source="lifeCycleGroupId" reference="lcaGroup" filterToQuery={searchText => ({ name: searchText })}>
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput label="Raw Material Category" source="rawMaterialCategoryId" reference="rawMaterialCategory" filterToQuery={searchText => ({ name: searchText })}>
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <SelectInput source="tier" choices={tierChoices} />

    <ReferenceInput label="Tertiary packaging" source="tertiaryPackagingId" reference="tertiaryPackaging" filterToQuery={searchText => ({ name: searchText })}>
      <AutocompleteInput optionText="name" />
    </ReferenceInput>

    <ReferenceInput label="Secondary packaging" source="secondaryPackagingId" reference="secondaryPackaging" filterToQuery={searchText => ({ name: searchText })}>
      <AutocompleteInput optionText="name" />
    </ReferenceInput>

    <ReferenceInput label="Primary packaging" source="primaryPackagingId" reference="primaryPackaging" filterToQuery={searchText => ({ name: searchText })}>
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

const ProcessList = (props) => {
  return (
    <List {...props} filters={Filters(props)}>
      <div style={{ marginTop: '15px' }}>
        <Datagrid rowClick="show">
          <TextField source="name" />
          <TextField source="shortName" label="Short name" />
          <TextField source="id" />
          <ReferenceField label="Category" source="categoryId" reference="lcaCategory">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField label="Group" source="lifeCycleGroupId" reference="lcaGroup">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField label="Raw material category" source="rawMaterialCategoryId" reference="rawMaterialCategory">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField label="Benchmark origin" source="benchmarkOriginId" reference="origins">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField label="Benchmark process" source="benchmarkProcessId" reference="process">
            <TextField source="name" />
          </ReferenceField>
          <TextField source="functionalUnit" label="Functional unit" />
          <BooleanField source="visibleInAppEcoCalculator" />
          <NumberField label="Bio-based content" source="bio_based_content" options={{ style: 'percent' }} />
          <NumberField label="Organic content" source="organic_content" options={{ style: 'percent' }} />
          <NumberField label="Recycled or reused content" source="recycled_or_reused_content" options={{ style: 'percent' }} />
          <DateField source="createdAt" label="Creation date" />
          <DateField source="updatedAt" label="Update date" />
          <EditButton />
        </Datagrid>
      </div>
    </List>
  );
};

const PreProcessList = (props) => {
  const record = useRecordContext();
  if (record) {
    if (record.preProcesses.length > 0) {
      return (
        <List {...props} actions={null} bulkActionButtons={false} resource="process" filter={{ id: record.preProcesses.map(x => x.id) }}>
          <Datagrid>
            <TextField source="name" />
            <TextField source="id" />
          </Datagrid>
        </List>);
    } else {
      return <span>There are no pre processes</span>
    }
  }
  return null;
}

const ShowProcess = (props) => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Main">
          <TextField source="name" />
          <TextField source="shortName" label="Short name" />
          <TextField source="id" />
          <ReferenceField label="Category" source="categoryId" reference="lcaCategory">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField label="Group" source="lifeCycleGroupId" reference="lcaGroup">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField label="Raw material category" source="rawMaterialCategoryId" reference="rawMaterialCategory">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField label="Benchmark origin" source="benchmarkOriginId" reference="origins">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField label="Benchmark process" source="benchmarkProcessId" reference="process">
            <TextField source="name" />
          </ReferenceField>
          <TextField source="functionalUnit" label="Functional unit" />
          <BooleanField source="visibleInAppEcoCalculator" />
          <NumberField label="Bio-based content" source="bio_based_content" options={{ style: 'percent' }} />
          <NumberField label="Organic content" source="organic_content" options={{ style: 'percent' }} />
          <NumberField label="Recycled or reused content" source="recycled_or_reused_content" options={{ style: 'percent' }} />
          <DateField source="createdAt" label="Creation date" />
          <DateField source="updatedAt" label="Update date" />
        </Tab>
        <Tab label="Pre Processes">
          <PreProcessList {...props} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

const CreateProcess = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="shortName" label="Short name" />
        <ReferenceInput label="Category" source="categoryId" reference="lcaCategory" filterToQuery={searchText => ({ name: searchText })}>
          <AutocompleteInput source="name" />
        </ReferenceInput>
        <ReferenceInput label="Group" source="lifeCycleGroupId" reference="lcaGroup" filterToQuery={searchText => ({ name: searchText })}>
          <AutocompleteInput source="name" />
        </ReferenceInput>
        <ReferenceInput label="Raw material category" source="rawMaterialCategoryId" reference="rawMaterialCategory" filterToQuery={searchText => ({ name: searchText })}>
          <AutocompleteInput source="name" />
        </ReferenceInput>
        <ReferenceInput label="Benchmark origin" source="benchmarkOriginId" reference="origins" filterToQuery={searchText => ({ name: searchText })}>
          <AutocompleteInput source="name" />
        </ReferenceInput>
        <ReferenceInput label="Benchmark process" source="benchmarkProcessId" reference="process" filterToQuery={searchText => ({ name: searchText })}>
          <AutocompleteInput source="name" />
        </ReferenceInput>
        <SelectInput source="functionalUnit" choices={FUNCTIONAL_UNITS} label="Functional unit" />
        <BooleanInput source="visibleInAppEcoCalculator" />
        <NumberInput label="Bio-based content" source="bio_based_content" />
        <NumberInput label="Organic content" source="organic_content" />
        <NumberInput label="Recycled or reused content" source="recycled_or_reused_content" />
      </SimpleForm>
    </Create>
  );
};

const EditProcess = (props) => {
  return (
    <Edit {...props}>
      <TabbedForm>
        <FormTab label='summary'>
          <TextInput source="name" />
          <TextInput source="shortName" label="Short name" />
          <ReferenceInput label="Category" source="categoryId" reference="lcaCategory" filterToQuery={searchText => ({ name: searchText })}>
            <AutocompleteInput source="name" />
          </ReferenceInput>
          <ReferenceInput label="Group" source="lifeCycleGroupId" reference="lcaGroup" filterToQuery={searchText => ({ name: searchText })}>
            <AutocompleteInput source="name" />
          </ReferenceInput>
          <ReferenceInput label="Raw material category" source="rawMaterialCategoryId" reference="rawMaterialCategory" filterToQuery={searchText => ({ name: searchText })}>
            <AutocompleteInput source="name" />
          </ReferenceInput>
          <ReferenceInput label="Benchmark origin" source="benchmarkOriginId" reference="origins" filterToQuery={searchText => ({ name: searchText })}>
            <AutocompleteInput source="name" />
          </ReferenceInput>
          <ReferenceInput label="Benchmark process" source="benchmarkProcessId" reference="process" filterToQuery={searchText => ({ name: searchText })}>
            <AutocompleteInput source="name" />
          </ReferenceInput>
          <SelectInput source="functionalUnit" choices={FUNCTIONAL_UNITS} label="Functional unit" />
          <BooleanInput source="visibleInAppEcoCalculator" />
          <NumberInput label="Bio-based content" source="bio_based_content" />
          <NumberInput label="Organic content" source="organic_content" />
          <NumberInput label="Recycled or reused content" source="recycled_or_reused_content" />
        </FormTab>
        <FormTab label='pre processes'>
          <ArrayInput source="preProcesses">
            <SimpleFormIterator disableReordering getItemLabel={(index) => `${index + 1}`}>
              <ReferenceInput label="name" source="id" reference="process" filterToQuery={searchText => ({ name: searchText })}>
                <AutocompleteInput source="name" />
              </ReferenceInput>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};


const processResource = () => <Resource
  name="process"
  options={{ label: 'Processess', menuGroup: 'Bcome' }}
  list={ProcessList}
  create={CreateProcess}
  edit={EditProcess}
  show={ShowProcess} />;

export default processResource;
