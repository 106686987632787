import {createContext, useContext, useState} from "react";
import jwt_decode from "jwt-decode";

let initialState = null;
if (localStorage.getItem('bcome-admin-auth')) {
  initialState = JSON.parse(localStorage.getItem('bcome-admin-auth'));
}
const TokenContext = createContext({state:initialState, setState:null});
const useTokenContext = () => useContext(TokenContext);

const useSetState = () => {
  const setState = useTokenContext().setState;
  return state => {
    if (state) {
      localStorage.setItem('bcome-admin-auth', JSON.stringify(state))
    } else {
      localStorage.removeItem('bcome-admin-auth');
    }
    setState(state);
  }
}
export const useTokenOps = () => {
  const {state} = useTokenContext();
  const setState = useSetState();
  return {
    setRootToken : tokens => setState({rootToken: tokens, accountToken: tokens}),
    setAccountToken : tokens => setState({...state, accountToken: tokens}),
    exitAccountToken: () => setState({...state, accountToken: state.rootToken }),
    removeRootToken: () => setState(null),
    isSwitchAccount: state?.accountToken?.accessToken !== state?.rootToken?.accessToken,
    account: state?.accountToken ? {
      ...state.accountToken,
      data: state.accountToken ? jwt_decode(state.accountToken.accessToken) : {}
    } : {},
    root: state?.rootToken ? {
      ...state.rootToken,
      data: state.rootToken ? jwt_decode(state.rootToken.accessToken) : {}
    } : {},
  }
}

export const TokenContextProvider = props => {
  const [state, setState] = useState(initialState);
  return <TokenContext.Provider value={{state, setState}}>{props.children}</TokenContext.Provider>;
}


