import {
    List,
    Create,
    Edit,
    SimpleForm,
    TextInput,
    TextField,
    EditButton,
    DateField,
    Resource,
    Show,
  Datagrid,
  ReferenceField,
  BooleanField,
  ReferenceInput,
  BooleanInput,
  SelectInput,
  AutocompleteInput,
  ImageField
} from "react-admin";
import { RECYCLABILITY_FRANCE } from "../consts";
import { validateNumericInput } from "../utils/validators/validate-numeric";

  const RetailPackagingProcessesList = (props) => {
    return (
      <List {...props}>
        <div style={{marginTop: '15px'}}>
          <Datagrid rowClick="show">
            <TextField source="id" />
            <ReferenceField source="processId" reference="process">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Packaging category" source="packagingCategoryId" reference="packagingCategory">
                <TextField source="category" />
            </ReferenceField>
            <ImageField source="icon" className="retail-packaging-process" />
            <TextField label="Weight (kg)" source="weight" type="number" />
            <BooleanField source="reusability" />
            <TextField label="Recyclability in France" source="recyclabilityFR" />
            <DateField source="createdAt" label="Creation date" />
            <DateField source="updatedAt" label="Update date" />
            <EditButton />
          </Datagrid>
        </div>
      </List>
    );
  };

  const ShowRetailPackagingProcesses = (props) => {
    return (
      <Show {...props}>
        <SimpleForm>
            <TextField source="id" />
            <ReferenceField source="processId" reference="process">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Packaging category" source="packagingCategoryId" reference="packagingCategory">
                <TextField source="category" />
            </ReferenceField>
            <ImageField source="icon" className="retail-packaging-process" />
            <TextField label="Weight (kg)" source="weight" type="number" />
            <BooleanField source="reusability" />
            <TextField label="Recyclability in France" source="recyclabilityFR" />
            <DateField source="createdAt" label="Creation date" />
            <DateField source="updatedAt" label="Update date" />
        </SimpleForm>
      </Show>
    );
  };

  const CreateRetailPackagingProcesses = (props) => {
    return (
      <Create {...props}>
        <SimpleForm>
            <ReferenceInput source="processId" reference="process" filterToQuery={(searchText) => ({ name: `%${searchText}%` })}>
                <AutocompleteInput />
            </ReferenceInput>
            <ReferenceInput label="Packaging category" source="packagingCategoryId" reference="packagingCategory">
                <SelectInput optionText="category" />
            </ReferenceInput>
            <TextInput source="icon" />
            <TextInput label="Weight (kg)" source="weight" validate={validateNumericInput} />
            <BooleanInput source="reusability" defaultValue={false} />
            <SelectInput label="Recyclability in France" source="recyclabilityFR" choices={RECYCLABILITY_FRANCE} />
        </SimpleForm>
      </Create>
    );
  };

  const EditRetailPackagingProcesses = (props) => {
    return (
      <Edit {...props}>
        <SimpleForm>
            <ReferenceInput source="processId" reference="process" filterToQuery={(searchText) => ({ name: `%${searchText}%` })}>
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput label="Packaging category" source="packagingCategoryId" reference="packagingCategory">
                <SelectInput optionText="category" />
            </ReferenceInput>
            <TextInput source="icon" />
            <TextInput label="Weight (kg)" source="weight" validate={validateNumericInput}  />
            <BooleanInput source="reusability" defaultValue={false} />
            <SelectInput label="Recyclability in France" source="recyclabilityFR" choices={RECYCLABILITY_FRANCE} />
        </SimpleForm>
      </Edit>
    );
  };


  const retailPackagingProcessesResource = () => <Resource
    name="retailPackagingProcesses"
    options={{ label: 'Retail packaging processes', menuGroup: 'Bcome'}}
    list={RetailPackagingProcessesList}
    create={CreateRetailPackagingProcesses}
    edit={EditRetailPackagingProcesses}
    show={ShowRetailPackagingProcesses} />;

  export default retailPackagingProcessesResource;
