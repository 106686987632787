import {
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  TextField,
  EditButton,
  DateField,
  Resource,
  Filter,
  Show,
Datagrid } from "react-admin";


const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
  </Filter>
);

const RetailGroupList = (props) => {
  return (
    <List {...props} filters={Filters(props)}>
      <div style={{marginTop: '15px'}}>
        <Datagrid rowClick="show">
          <TextField source="name" />
          <TextField source="id" />
          <DateField source="createdAt" label="Creation date" />
          <DateField source="updatedAt" label="Update date" />
          <EditButton />
        </Datagrid>
      </div>
    </List>
  );
};

const ShowRetailGroup = (props) => {
  return (
    <Show {...props}>
      <SimpleForm>
        <TextField source="name" />
        <TextField source="id" />
        <DateField source="createdAt" label="Creation date" />
        <DateField source="updatedAt" label="Update date" />
      </SimpleForm>
    </Show>
  );
};

const CreateRetailGroup = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" />
      </SimpleForm>
    </Create>
  );
};

const EditRetailGroup = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" />
      </SimpleForm>
    </Edit>
  );
};


const retailGroupResource = () => <Resource
  name="retailGroup"
  options={{ label: 'Retail group', menuGroup: 'Bcome'}}
  list={RetailGroupList}
  create={CreateRetailGroup}
  edit={EditRetailGroup}
  show={ShowRetailGroup} />;

export default retailGroupResource;
