import {
    List,
    Create,
    Edit,
    SimpleForm,
    TextInput,
    TextField,
    EditButton,
    DateField,
    Resource,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    Show,
    Filter,
    AutocompleteInput,
    Datagrid } from "react-admin";


  const Filters = (props) => (
    <Filter {...props}>
      <TextInput label="Search" source="article.name" alwaysOn />
      <ReferenceInput label="Supplier" source="supplier.id" reference="supplier" filterToQuery={searchText => ({ name: searchText })}>
        <AutocompleteInput filterToQuery={searchText => ({ supplierName_ilike: `%${searchText}%` })} optionText={"supplierName"} />
      </ReferenceInput>
      <ReferenceInput label="Origin" source="originId" reference="origins" filterToQuery={searchText => ({ name: searchText })} >
          <AutocompleteInput optionText="name"  />
      </ReferenceInput>
    </Filter>
  );

  const ArticleSupplierList = (props) => {
    return (
      <List {...props} filters={Filters(props)}>
        <div style={{marginTop: '15px'}}>
          <Datagrid rowClick="show">
            <ReferenceField label="Article" source="articleId" reference="articles">
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Supplier" source="supplierId" reference="supplier">
              <TextField source="supplierName" />
            </ReferenceField>
            <ReferenceField label="Origin" source="supplier.origin.id" reference="origins">
              <TextField source="name" />
            </ReferenceField>
            <DateField source="createdAt" label="Creation date" />
            <DateField source="updatedAt" label="Update date" />
            <EditButton />
          </Datagrid>
        </div>
      </List>
    );
  };

  const ShowArticleSupplier = (props) => {
    return (
      <Show {...props}>
        <SimpleForm>
          <ReferenceField label="Article" source="articleId" reference="articles">
            <TextField source="name" />
          </ReferenceField>

          <ReferenceField label="Supplier" source="supplierId" reference="supplier">
            <TextField source="supplierName" />
          </ReferenceField>
          <ReferenceField label="Origin" source="supplier.origin.id" reference="origins" >
            <TextField source="name" />
          </ReferenceField>
          <DateField source="createdAt" label="Creation date" />
          <DateField source="updatedAt" label="Update date" />
          <EditButton />
        </SimpleForm>
      </Show>
    );
  };

  const CreateArticleSupplier = (props) => {
    return (
      <Create {...props}>
        <SimpleForm>
          <ReferenceInput label="Article" source="articleId" reference="articles">
            <SelectInput optionText="name" required={true} />
          </ReferenceInput>
          <ReferenceInput label="Supplier" source="supplierId" reference="supplier">
            <SelectInput optionText="supplierName" required={true} />
          </ReferenceInput>
          <ReferenceInput label="Origin" source="originId" reference="origins">
            <SelectInput optionText="name" disabled />
          </ReferenceInput>
        </SimpleForm>
      </Create>
    );
  };

  const EditArticleSupplier = (props) => {
    return (
      <Edit {...props}>
        <SimpleForm>
          <ReferenceInput label="Article" source="articleId" reference="articles">
            <SelectInput optionText="name" required={true} />
          </ReferenceInput>

          <ReferenceInput label="Supplier" source="supplierId" reference="supplier">
            <SelectInput optionText="supplierName" required={true} />
          </ReferenceInput>
          <ReferenceInput label="Origin" source="supplier.origin.id" reference="origins">
            <SelectInput optionText="name" disabled />
          </ReferenceInput>
        </SimpleForm>
      </Edit>
    );
  };



  const articleSupplierResource = () => <Resource
    name="articleSupplier"
    options={{ label: 'Article supplier', menuGroup: 'Articles'}}
    list={ArticleSupplierList}
    create={CreateArticleSupplier}
    edit={EditArticleSupplier}
    show={ShowArticleSupplier}
/>;

  export default articleSupplierResource;