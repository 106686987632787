import {
    List,
    Create,
    Edit,
    SimpleForm,
    TextField,
    EditButton,
    DateField,
    Resource,
    Show,
    ReferenceInput,
    ReferenceField,
    Filter,
    AutocompleteInput,
    Datagrid } from "react-admin";


const Filters = (props) => (
    <Filter {...props}>

        <ReferenceInput alwaysOn label="Process" source="processId" reference="process" filterToQuery={searchText => ({ name: searchText })} >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>

        <ReferenceInput alwaysOn label="Material Category" source="materialsCategoryId" reference="materialsCategory" filterToQuery={searchText => ({ name: searchText })} >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

const ProcessMaterialsCategoryList = (props) => {
    return (
        <List {...props} filters={Filters(props)}>
            <div style={{ marginTop: '15px' }}>
                <Datagrid rowClick="show">
                    <TextField source="id" />
                    <ReferenceField label="Process" source="processId" reference="process">
                        <TextField source="name" />
                    </ReferenceField>

                    <ReferenceField label="Material Category" source="materialsCategoryId" reference="materialsCategory">
                        <TextField source="name" />
                    </ReferenceField>

                    <DateField source="createdAt" label="Creation date" />
                    <DateField source="updatedAt" label="Update date" />
                    <EditButton />
                </Datagrid>
            </div>
        </List>
    );
};

const ShowProcessMaterialsCategory = (props) => {
    return (
        <Show {...props}>
            <SimpleForm>
                <TextField source="id" />
                <ReferenceField label="Process" source="processId" reference="process">
                    <TextField source="name" />
                </ReferenceField>

                <ReferenceField label="Material Category" source="materialsCategoryId" reference="materialsCategory">
                    <TextField source="name" />
                </ReferenceField>

                <DateField source="createdAt" label="Creation date" />
                <DateField source="updatedAt" label="Update date" />
            </SimpleForm>
        </Show>
    );
};

const CreateProcessMaterialsCategory = (props) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <ReferenceInput label="Process" source="processId" reference="process" filterToQuery={searchText => ({ name: searchText })}>
                    <AutocompleteInput optionText="name" required={true} />
                </ReferenceInput>

                <ReferenceInput label="Material Category" source="materialsCategoryId" reference="materialsCategory" filterToQuery={searchText => ({ name: searchText })}>
                    <AutocompleteInput source="name" />
                </ReferenceInput>

            </SimpleForm>
        </Create>
    );
};

const EditProcessMaterialsCategory = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <ReferenceInput label="Process" source="processId" reference="process" filterToQuery={searchText => ({ name: searchText })}>
                    <AutocompleteInput source="name" required={true} />
                </ReferenceInput>

                <ReferenceInput label="Material Category" source="materialsCategoryId" reference="materialsCategory" filterToQuery={searchText => ({ name: searchText })}>
                    <AutocompleteInput source="name" required={true} />
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    );
};


const processMaterialsCategoryResource = () => <Resource
    name="processMaterialsCategory"
    options={{ label: 'Process material category', menuGroup: 'Bcome' }}
    list={ProcessMaterialsCategoryList}
    create={CreateProcessMaterialsCategory}
    edit={EditProcessMaterialsCategory}
    show={ShowProcessMaterialsCategory} />;

export default processMaterialsCategoryResource;