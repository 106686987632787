import React from 'react';
import { getResources, MenuItemLink, Responsive } from 'react-admin';
import { connect } from 'react-redux';
import { withStyles, Collapse, List } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import DefaultIcon from '@material-ui/icons/ViewList';
import {useTokenOps} from "./TokenContext";
const styles = {
  root: {}, // Style applied to the MenuItem from material-ui
  active: { fontWeight: 'bold' }, // Style applied when the menu item is the active one
  icon: {}, // Style applied to the icon
  companyName: {
    fontWeight: 600,
    fontSize: '20px',
    color: 'black',
  },
};

const groupBy = (xs, group) => {
  return xs.reduce((rv, x) => {
    (rv[group(x)] = rv[group(x)] || []).push(x);
    return rv;
  }, {});
};

const menuGroups = [
  'Bcome',
  'Accounts',
  'Projects',
  'Articles',
  'Materials',
  'Suppliers',
  'Packaging',
  'Glossary',
  'Upload Status'
];

class NestedList extends React.Component {
  state = { open: false };

  nestedStyle = {
    paddingLeft: '4px',
    fontStyle: 'italic',
  };

  handleClick = open => () => {
    this.setState({ open });
  };

  render() {
    const { title } = this.props;

    return (
      <div style={this.props.nested ? this.nestedStyle : undefined}>
        <List>
          <ListItem button onClick={this.handleClick(!this.state.open)}>
            {/* {this.props.icon && <ListItemIcon>{this.props.icon}</ListItemIcon>} */}
            <ListItemText primary={title} />
            {this.state.open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={this.state.open} timeout="auto" unmountOnExit>
            <List
              component="div"
              disablePadding
              style={this.props.nested ? this.nestedStyle : undefined}
            >
              {this.props.children}
            </List>
          </Collapse>
        </List>
      </div>
    );
  }
}

const CustomMenu = ({ resources, onMenuClick, logout, dense, classes }) => {
  const tokenOps = useTokenOps();
  const groupResources = groupBy(resources, resource => resource.options.menuGroup);
  const menuItems = menuGroups.map(menuGroup => (
      <>
        <Divider key="divider" />
        <NestedList title={menuGroup}>
          {groupResources[menuGroup] &&
            groupResources[menuGroup].map(resource => (
              <MenuItemLink
                key={resource.name}
                to={`/${resource.name}`}
                primaryText={`${resource.options.label}`}
                leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
                onClick={onMenuClick}
                dense={dense}
              />
            ))}
        </NestedList>
      </>
  ));

  return (
    <div>
      {tokenOps.account && (<MenuItemLink
        primaryText={<span className={classes.companyName}>{tokenOps.account.data?.accountName}</span>}
        to={`/accounts/${tokenOps.account.data?.accountId}/show`}
      />)}
      {menuItems}
      <Responsive
        small={logout}
        medium={null} // Pass null to render nothing on larger devices
      />
    </div>
  );
};



const mapStateToProps = state => ({
  resources: getResources(state),
});

export default withRouter(connect(mapStateToProps)(withStyles(styles)(CustomMenu)));


