import {
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  TextField,
  EditButton,
  DateField,
  Resource,
  Filter,
  Show,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  Datagrid } from "react-admin";


const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
  </Filter>
);

const RawMaterialCategoryList = (props) => {
  return (
    <List {...props} filters={Filters(props)}>
      <div style={{marginTop: '15px'}}>
        <Datagrid rowClick="show">
          <TextField source="name" />
          <TextField source="id" />
          <ReferenceField label="Raw material group" source="rawMaterialCategoryGroupId" reference="rawMaterialGroup">
            <TextField source="name" />
          </ReferenceField>
          <DateField source="createdAt" label="Creation date" />
          <DateField source="updatedAt" label="Update date" />
          <EditButton />
        </Datagrid>
      </div>
    </List>
  );
};

const ShowRawMaterialCategory = (props) => {
  return (
    <Show {...props}>
      <SimpleForm>
        <TextField source="name" />
        <TextField source="id" />
        <ReferenceField label="Raw material group" source="rawMaterialCategoryGroupId" reference="rawMaterialGroup">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="createdAt" label="Creation date" />
        <DateField source="updatedAt" label="Update date" />
      </SimpleForm>
    </Show>
  );
};

const CreateRawMaterialCategory = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <ReferenceInput label="Raw material group" source="rawMaterialCategoryGroupId" reference="rawMaterialGroup">
          <AutocompleteInput source="name" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

const EditRawMaterialCategory = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <ReferenceInput label="Raw material group" source="rawMaterialCategoryGroupId" reference="rawMaterialGroup">
          <AutocompleteInput source="name" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};


const rawMaterialCategoryResource = () => <Resource
  name="rawMaterialCategory"
  options={{ label: 'Raw material category', menuGroup: 'Bcome'}}
  list={RawMaterialCategoryList}
  create={CreateRawMaterialCategory}
  edit={EditRawMaterialCategory}
  show={ShowRawMaterialCategory} />;

export default rawMaterialCategoryResource;
