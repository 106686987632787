import {
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  TextField,
  EditButton,
  DateField,
  Resource,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  Show,
  Filter,
  Datagrid } from "react-admin";

const Filters = (props) => {
  return(
    <Filter {...props}>
      <TextInput label="Search" source="article.name" alwaysOn />
    </Filter>
  )
};

const ArticleMaterialList = (props) => {
  return (
    <List {...props} filters={Filters(props)}>
      <div style={{ marginTop: '15px' }}>
        <Datagrid rowClick="show">
          <ReferenceField label="Article" source="articleId" reference="articles">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField label="Material" source="materialId" reference="material">
            <TextField source="materialName" />
          </ReferenceField>
          <TextField source="amount" />

          <ReferenceField label="Account" source="accountId" reference="accounts">
            <TextField source="name" />
          </ReferenceField>
          <DateField source="createdAt" label="Creation date" />
          <DateField source="updatedAt" label="Update date" />
          <EditButton />
        </Datagrid>
      </div>
    </List>
  );
};

const ShowArticleMaterial = (props) => {
  return (
    <Show {...props}>
      <SimpleForm>
        <ReferenceField label="Article" source="articleId" reference="articles">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Material" source="materialId" reference="material">
          <TextField source="materialName" />
        </ReferenceField>
        <TextField source="amount" />

        <ReferenceField label="Account" source="accountId" reference="accounts">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="createdAt" label="Creation date" />
        <DateField source="updatedAt" label="Update date" />
        <EditButton />
      </SimpleForm>
    </Show>
  );
};

const CreateArticleMaterial = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <ReferenceInput label="Article" source="articleId" reference="articles" filterToQuery={searchText => ({ name: searchText })}>
          <AutocompleteInput optionText="name" required={true} />
        </ReferenceInput>
        <ReferenceInput label="Material" source="materialId" reference="material" filterToQuery={searchText => ({ name: searchText })}>
          <AutocompleteInput optionText="materialName" required={true} />
        </ReferenceInput>
        <TextInput source="amount" required={true} />
      </SimpleForm>
    </Create>
  );
};

const EditArticleMaterial = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <ReferenceInput label="Article" source="articleId" reference="articles" filterToQuery={searchText => ({ name: searchText })}>
          <AutocompleteInput optionText="name" required={true} />
        </ReferenceInput>
        <ReferenceInput label="Material" source="materialId" reference="material" filterToQuery={searchText => ({ name: searchText })}>
          <AutocompleteInput optionText="materialName" required={true} />
        </ReferenceInput>
        <TextInput source="amount" required={true} />
      </SimpleForm>
    </Edit>
  );
};



const articleMaterialResource = () => <Resource
  name="articleMaterial"
  options={{ label: 'Article material', menuGroup: 'Articles' }}
  list={ArticleMaterialList}
  create={CreateArticleMaterial}
  edit={EditArticleMaterial}
  show={ShowArticleMaterial} />;

export default articleMaterialResource;
