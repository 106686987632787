import {
    BooleanField, DateField, Edit,
    EditButton,
    List,
    BooleanInput,
    Resource, Show, SimpleForm,
    TextField, TextInput, ReferenceField, Filter, ReferenceInput, SelectInput, Datagrid } from "react-admin";
import React from "react";

const Filters = (props) => (
    <Filter {...props}>
      <ReferenceInput label="Best Practices" source="id" reference="bestPractices">
          <SelectInput optionText="name" />
      </ReferenceInput>
    </Filter>
  );

const AccountBestPracticesList = (props) => {
    return (
        <List {...props} filters={Filters(props)}>
            <div style={{marginTop: '15px'}}>
                <Datagrid rowClick="show">
                    <ReferenceField label="Best Practices" source="bestPracticeId" reference="bestPractices">
                        <TextField source="name"/>
                    </ReferenceField>
                    <BooleanField source="declared" label="Declared"/>
                    <TextField source="comments" label="Comments"/>
                    <EditButton/>
                </Datagrid>
            </div>
        </List>
    );
};


const EditAccountBestPractice = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput disabled source="bestPractice.name"/>
                <BooleanInput label="Declared" source="declared"/>
                <TextInput multiline source="comments" label="Comments"/>
            </SimpleForm>
        </Edit>
    );
};

const ShowAccountBestPractice = (props) => {
    return (
        <Show {...props}>
            <SimpleForm>
                <ReferenceField label="Best Practices" source="bestPracticeId" reference="bestPractices">
                    <TextField source="name"/>
                </ReferenceField>
                <BooleanField source="declared" label="Declared"/>
                <TextField source="comments" label="Comments"/>
                <DateField source="createdAt" label="Creation date"/>
                <DateField source="updatedAt" label="Update date"/>
            </SimpleForm>
        </Show>
    );
};

const accountBestPracticesResource = () => <Resource
    name="filterAccountBestPractices"
    options={{label: 'Best practices', menuGroup: 'Accounts'}}
    edit={EditAccountBestPractice}
    show={ShowAccountBestPractice}
    list={AccountBestPracticesList}/>;

export default accountBestPracticesResource
