import {
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  TextField,
  EditButton,
  DateField,
  Resource,
  Show,
  ReferenceField,
  SelectInput,
  Filter,
  ReferenceInput,
Datagrid } from "react-admin";


const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
    <ReferenceInput label="Project" source="projectId" reference="projects">
        <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput label="Type" source="typeId" reference="lcaGroup">
        <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput label="Category" source="categoryId" reference="materialsCategory">
        <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

const MaterialList = (props) => {
  return (
    <List {...props} filters={Filters(props)}>
      <div style={{marginTop: '15px'}}>
        <Datagrid rowClick="show">
          <TextField source="materialName" label="Name" />
          <TextField source="id" />
          <ReferenceField label="Project" source="projectId" reference="projects">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField label="Category" source="categoryId" reference="materialsCategory">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField label="Application" source="applicationId" reference="materialsApplication">
            <TextField source="name" />
          </ReferenceField>

          <TextField source="comments"  label="Comments"/>


          <DateField source="createdAt" label="Creation date" />
          <DateField source="updatedAt" label="Update date" />
          <EditButton />
        </Datagrid>
      </div>
    </List>
  );
};

const ShowMaterial = (props) => {
  return (
    <Show {...props}>
      <SimpleForm>
        <TextField source="name" />
        <ReferenceField label="Project" source="projectId" reference="projects">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Type" source="typeId" reference="lcaGroup">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Category" source="categoryId" reference="materialsCategory">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Application" source="applicationId" reference="materialsApplication">
          <TextField source="name" />
        </ReferenceField>

        <TextField label="Material reference" source="material_reference" />

        <DateField source="createdAt" label="Creation date" />
        <DateField source="updatedAt" label="Update date" />
      </SimpleForm>
    </Show>
  );
};

const CreateMaterial = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <ReferenceInput label="Type" source="typeId" reference="lcaGroup">
          <SelectInput source="name" />
        </ReferenceInput>
        <ReferenceInput label="Category" source="categoryId" reference="materialsCategory">
          <SelectInput source="name" />
        </ReferenceInput>
        <ReferenceInput label="Application" source="applicationId" reference="materialsApplication">
          <SelectInput source="name" />
        </ReferenceInput>
        <TextInput label="W BC" source="waterByMaterialComposition" />
        <TextInput label="CC BC" source="climateChangeByMaterialComposition" />
        <TextInput label="E BC" source="eutrophicationByMaterialComposition" />
        <TextInput label="FF BC" source="fossilFuelsByMaterialComposition" />
        <TextInput label="W BP" source="waterByProcess" />
        <TextInput label="CC BP" source="climateChangeByProcess" />
        <TextInput label="E BP" source="eutrophicationByProcess" />
        <TextInput label="FF BP" source="fossilFuelsByProcess" />
        <TextInput label="Material reference" source="material_reference" />
      </SimpleForm>
    </Create>
  );
};

const EditMaterial = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <ReferenceInput label="Type" source="typeId" reference="lcaGroup">
          <SelectInput source="name" />
        </ReferenceInput>
        <ReferenceInput label="Category" source="categoryId" reference="materialsCategory">
          <SelectInput source="name" />
        </ReferenceInput>
        <ReferenceInput label="Application" source="applicationId" reference="materialsApplication">
          <SelectInput source="name" />
        </ReferenceInput>
        <TextInput label="W BC" source="waterByMaterialComposition" />
        <TextInput label="CC BC" source="climateChangeByMaterialComposition" />
        <TextInput label="E BC" source="eutrophicationByMaterialComposition" />
        <TextInput label="FF BC" source="fossilFuelsByMaterialComposition" />
        <TextInput label="W BP" source="waterByProcess" />
        <TextInput label="CC BP" source="climateChangeByProcess" />
        <TextInput label="E BP" source="eutrophicationByProcess" />
        <TextInput label="FF BP" source="fossilFuelsByProcess" />
        <TextInput label="Material reference" source="material_reference" />
      </SimpleForm>
    </Edit>
  );
};


const materialResource = () => <Resource
  name="material"
  options={{ label: 'Materials', menuGroup: 'Materials'}}
  list={MaterialList}
  create={CreateMaterial}
  edit={EditMaterial}
  show={ShowMaterial} />;

export default materialResource;
