import {
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  TextField,
  EditButton,
  DateField,
  Resource,
  Filter,
  Show,
  Datagrid
} from "react-admin";


const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="category" alwaysOn />
  </Filter>
);

const PackagingCategoryList = (props) => {
  return (
    <List {...props} filters={Filters(props)}>
      <div style={{marginTop: '15px'}}>
        <Datagrid rowClick="show">
          <TextField source="id" />
          <TextField source="category" label="Name" />
          <DateField source="createdAt" label="Creation date" />
          <DateField source="updatedAt" label="Update date" />
          <EditButton />
        </Datagrid>
      </div>
    </List>
  );
};

const ShowPackagingCategory = (props) => {
  return (
    <Show {...props}>
      <SimpleForm>
        <TextField source="id" />
        <TextField source="category" label="Name" />
        <DateField source="createdAt" label="Creation date" />
        <DateField source="updatedAt" label="Update date" />
      </SimpleForm>
    </Show>
  );
};

const CreatePackagingCategory = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="category" label="Name" />
      </SimpleForm>
    </Create>
  );
};

const EditPackagingCategory = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="category" label="Name" />
      </SimpleForm>
    </Edit>
  );
};


const packagingCategoryResource = () => <Resource
  name="packagingCategory"
  options={{ label: 'Packaging category', menuGroup: 'Bcome'}}
  list={PackagingCategoryList}
  create={CreatePackagingCategory}
  edit={EditPackagingCategory}
  show={ShowPackagingCategory} />;

export default packagingCategoryResource;
