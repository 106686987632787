import {
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  TextField,
  EditButton,
  DateField,
  Resource,
  Show,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  Filter,
  AutocompleteInput,
  Datagrid } from "react-admin";
import { DEFAULT_TYPES } from "../consts";

const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />

    <ReferenceInput alwaysOn label="Process" source="processId" reference="process" filterToQuery={searchText => ({ name: searchText })} >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>

    <ReferenceInput label="Origin" source="originId" reference="origins" filterToQuery={searchText => ({ name: searchText })} >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

const ProcessOriginList = (props) => {
  return (
    <List {...props} filters={Filters(props)}>
      <div style={{ marginTop: '15px' }}>
        <Datagrid rowClick="show">
          <TextField source="id" />
          <ReferenceField label="Process" source="processId" reference="process">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField label="Origin" source="originId" reference="origins">
            <TextField source="name" />
          </ReferenceField>

          <TextField source="description" />
          <TextField source="primarySource" label="Primary source" />
          <TextField source="dataGenerator" label="Data generator" />
          <TextField source="impactGenerator" label="Impact generator" />
          <TextField source="originalDataset" label="Original dataset" />
          <TextField source="systemBoundaries" label="System boundaries" />
          <TextField source="mainAssumptions" label="Main assumptions" />
          <TextField source="allocation" />
          <TextField source="water" />
          <TextField source="climateChange" label="Global warming" />
          <TextField source="eutrophication" />
          <TextField source="fossilFuels" label="Fossil fuels" />
          <TextField source="type" label="Type" />

          <DateField source="createdAt" label="Creation date" />
          <DateField source="updatedAt" label="Update date" />
          <EditButton />
        </Datagrid>
      </div>
    </List>
  );
};

const ShowProcessOrigin = (props) => {
  return (
    <Show {...props}>
      <SimpleForm>
        <TextField source="id" />
        <ReferenceField label="Process" source="processId" reference="process">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Origin" source="originId" reference="origins">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="description" />
        <TextField source="primarySource" label="Primary source" />
        <TextField source="dataGenerator" label="Data generator" />
        <TextField source="impactGenerator" label="Impact generator" />
        <TextField source="originalDataset" label="Original dataset" />
        <TextField source="systemBoundaries" label="System boundaries" />
        <TextField source="mainAssumptions" label="Main assumptions" />
        <TextField source="allocation" />
        <TextField source="water" />
        <TextField source="climateChange" label="Global warming" />
        <TextField source="eutrophication" />
        <TextField source="fossilFuels" label="Fossil fuels" />
        <TextField source="type" label="Type" />

        <DateField source="createdAt" label="Creation date" />
        <DateField source="updatedAt" label="Update date" />
      </SimpleForm>
    </Show>
  );
};

const CreateProcessOrigin = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <ReferenceInput label="Process" source="processId" reference="process" filterToQuery={searchText => ({ name: searchText })}>
          <AutocompleteInput optionText="name" required={true} />
        </ReferenceInput>

        <ReferenceInput label="Origin" source="originId" reference="origins" filterToQuery={searchText => ({ name: searchText })}>
          <AutocompleteInput optionText="name" required={true} />
        </ReferenceInput>
        <TextInput source="description" />
        <TextInput source="primarySource" label="Primary source" />
        <TextInput source="dataGenerator" label="Data generator" />
        <TextInput source="impactGenerator" label="Impact generator" />
        <TextInput source="originalDataset" label="Original dataset" />
        <TextInput source="systemBoundaries" label="System boundaries" />
        <TextInput source="allocation" />
        <TextInput source="mainAssumptions" label="Main assumptions" />

        <TextInput source="water" required={true} />
        <TextInput source="climateChange" required={true} />
        <TextInput source="eutrophication" required={true} />
        <TextInput source="fossilFuels" required={true} />
        <SelectInput source="type" label="Type" required={true} choices={DEFAULT_TYPES} />
      </SimpleForm>
    </Create>
  );
};

const EditProcessOrigin = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <ReferenceInput label="Process" source="processId" reference="process" filterToQuery={searchText => ({ name: searchText })}>
          <AutocompleteInput source="name" required={true} />
        </ReferenceInput>
        <ReferenceInput label="Origin" source="originId" reference="origins" filterToQuery={searchText => ({ name: searchText })}>
          <AutocompleteInput source="name" required={true} />
        </ReferenceInput>
        <TextInput source="description" />
        <TextInput source="primarySource" label="Primary source" />
        <TextInput source="dataGenerator" label="Data generator" />
        <TextInput source="impactGenerator" label="Impact generator" />
        <TextInput source="originalDataset" label="Original dataset" />
        <TextInput source="systemBoundaries" label="System boundaries" />
        <TextInput source="allocation" />
        <TextInput source="mainAssumptions" label="Main assumptions" />
        <TextInput source="water" required={true} />
        <TextInput source="climateChange" required={true} />
        <TextInput source="eutrophication" required={true} />
        <TextInput source="fossilFuels" required={true} />
        <SelectInput source="type" label="Type" required={true} choices={DEFAULT_TYPES} />
      </SimpleForm>
    </Edit>
  );
};


const processOriginResource = () => <Resource
  name="processOrigin"
  options={{ label: 'Process by origin', menuGroup: 'Bcome' }}
  list={ProcessOriginList}
  create={CreateProcessOrigin}
  edit={EditProcessOrigin}
  show={ShowProcessOrigin} />;

export default processOriginResource;
