import {
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  TextField,
  EditButton,
  DateField,
  Resource,
  Show,
  ReferenceInput,
  SelectInput,
  Filter,
  ReferenceField,
  AutocompleteInput,
  Datagrid } from "react-admin";


const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
    <ReferenceInput label="Transport" source="transportId" reference="transport">
        <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput label="Origin" source="originId" reference="origins" filterToQuery={searchText => ({ name: searchText })} >
        <AutocompleteInput optionText="name"  />
    </ReferenceInput>
    <ReferenceInput label="Destination" source="destinationId" reference="origins">
        <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

const TransportOriginList = (props) => {
  return (
    <List {...props} filters={Filters(props)}>
      <div style={{marginTop: '15px'}}>
        <Datagrid rowClick="show">
          <TextField source="id" />
          <ReferenceField label="Transport" source="transportId" reference="transport">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField label="Origin" source="originId" reference="origins">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField label="Destination" source="destinationId" reference="origins">
            <TextField source="name" />
          </ReferenceField>
          <TextField source="km" />
          <DateField source="createdAt" label="Creation date" />
          <DateField source="updatedAt" label="Update date" />
          <EditButton />
        </Datagrid>
      </div>
    </List>
  );
};

const ShowTransportOrigin = (props) => {
  return (
    <Show {...props}>
      <SimpleForm>
        <TextField source="id" />
        <ReferenceField label="Transport" source="transportId" reference="transport">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Origin" source="originId" reference="origins">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Destination" source="destinationId" reference="origins">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="km" />
        <DateField source="createdAt" label="Creation date" />
        <DateField source="updatedAt" label="Update date" />
      </SimpleForm>
    </Show>
  );
};

const CreateTransportOrigin = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <ReferenceInput label="Transport" source="transportId" reference="transport">
          <SelectInput source="name" />
        </ReferenceInput>
        <ReferenceInput label="Origin" source="originId" reference="origins">
          <SelectInput source="name" />
        </ReferenceInput>
        <ReferenceInput label="Destination" source="destinationId" reference="origins">
          <SelectInput source="name" />
        </ReferenceInput>
        <TextInput source="km" />
      </SimpleForm>
    </Create>
  );
};

const EditTransportOrigin = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <ReferenceInput label="Transport" source="transportId" reference="transport">
          <SelectInput source="name" />
        </ReferenceInput>
        <ReferenceInput label="Origin" source="originId" reference="origins">
          <SelectInput source="name" />
        </ReferenceInput>
        <ReferenceInput label="Destination" source="destinationId" reference="origins">
          <SelectInput source="name" />
        </ReferenceInput>
        <TextInput source="km" />
      </SimpleForm>
    </Edit>
  );
};


const transportOriginResource = () => <Resource
  name="transportOrigin"
  options={{ label: 'Transport by origin', menuGroup: 'Bcome'}}
  list={TransportOriginList}
  create={CreateTransportOrigin}
  edit={EditTransportOrigin}
  show={ShowTransportOrigin} />;

export default transportOriginResource;
