import {
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  TextField,
  EditButton,
  DateField,
  Resource,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  Show,
  Filter,
  AutocompleteInput,
  Datagrid } from "react-admin";

const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="article.name" alwaysOn />
    <ReferenceInput label="Process" source="processId" reference="process" filterToQuery={searchText => ({ name: searchText })}>
        <AutocompleteInput optionText="name"  />
    </ReferenceInput>
    <ReferenceInput label="Category" source="process.categoryId" reference="lcaCategory" filterToQuery={searchText => ({ name: searchText })} >
        <AutocompleteInput optionText="name"  />
    </ReferenceInput>
    <ReferenceInput label="Origin" source="originId" reference="origins" filterToQuery={searchText => ({ name: searchText })} >
        <AutocompleteInput optionText="name"  />
    </ReferenceInput>
    <ReferenceInput label="Transport" source="supplier.transportId" reference="transport" filterToQuery={searchText => ({ name: searchText })} >
        <AutocompleteInput optionText="name"  />
    </ReferenceInput>
  </Filter>
);

const SubReference = ({ children, ...props }) => {
  const { record } = props;
  return <ReferenceField {...props} source="categoryId" reference="lcaCategory" filter={{ id: record.categoryId }}>{children}</ReferenceField>
}

const ArticleProcessList = (props) => {
  return (
    <List {...props} filters={Filters(props)}>
      <div style={{marginTop: '15px'}}>
        <Datagrid rowClick="show">
          <ReferenceField label="Article" source="articleId" reference="articles">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField label="Process" source="processId" reference="process">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField label="Category" source="processId" reference="process">
            <SubReference source="proces.categoryId" reference="lcaCategory">
                <TextField source="name" />
            </SubReference>
          </ReferenceField>
          <ReferenceField label="Supplier" source="supplierId" reference="supplier">
            <TextField source="supplierName" />
          </ReferenceField>
          <ReferenceField label="Origin" source="originId" reference="origins" >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField label="Transport" source="supplierId" reference="supplier">
            <ReferenceField label="Transport" source="transportId" reference="transport" filter={{ id: "supplier.transportId" }}>
              <TextField source="name" />
            </ReferenceField>
          </ReferenceField>

          <TextField source="relativeAmount" />
          <TextField source="order" />

          <ReferenceField label="Account" source="accountId" reference="accounts">
            <TextField source="name" />
          </ReferenceField>
          <DateField source="createdAt" label="Creation date" />
          <DateField source="updatedAt" label="Update date" />
          <EditButton />
        </Datagrid>
      </div>
    </List>
  );
};

const ShowArticleProcess = (props) => {
  return (
    <Show {...props}>
      <SimpleForm>
        <TextField source="relativeAmount" />
        <ReferenceField label="Article" source="articleId" reference="articles">
          <TextField source="name" />
        </ReferenceField>

        <ReferenceField label="Process" source="processId" reference="process">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Category" source="processId" reference="process">
          <SubReference source="proces.categoryId" reference="lcaCategory">
              <TextField source="name" />
          </SubReference>
        </ReferenceField>
        <ReferenceField label="Supplier" source="supplierId" reference="supplier">
          <TextField source="supplierName" />
        </ReferenceField>
        <ReferenceField label="Origin" source="originId" reference="origins">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Transport" source="supplierId" reference="supplier">
          <ReferenceField label="Transport" source="transportId" reference="transport" filter={{ id: "supplier.transportId" }}>
            <TextField source="name" />
          </ReferenceField>
        </ReferenceField>

        <TextField source="relativeAmount" />
        <TextField source="order" />
        <ReferenceField label="Account" source="accountId" reference="accounts">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="createdAt" label="Creation date" />
        <DateField source="updatedAt" label="Update date" />
        <EditButton />
      </SimpleForm>
    </Show>
  );
};

const CreateArticleProcess = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="relativeAmount" />
        <ReferenceInput label="Article" source="articleId" reference="articles">
          <SelectInput optionText="name" required={true} />
        </ReferenceInput>
        <ReferenceInput label="Process" source="processId" reference="process">
          <SelectInput optionText="name" required={true} />
        </ReferenceInput>
        <ReferenceInput label="Supplier" source="supplierId" reference="supplier">
          <SelectInput optionText="supplierName" required={true} />
        </ReferenceInput>
        <ReferenceInput label="Origin" source="originId" reference="origins">
          <SelectInput optionText="name" required={true} />
        </ReferenceInput>
        <ReferenceInput label="Transport" source="transportId" reference="transport">
          <SelectInput optionText="name" required={true} />
        </ReferenceInput>

        <TextInput source="order" />
      </SimpleForm>
    </Create>
  );
};

const EditArticleProcess = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
      <TextInput source="relativeAmount" />
        <ReferenceInput label="Article" source="articleId" reference="articles">
          <SelectInput optionText="name" required={true} />
        </ReferenceInput>

        <ReferenceInput label="Process" source="processId" reference="process">
          <SelectInput optionText="name" required={true} />
        </ReferenceInput>
        <ReferenceInput label="Supplier" source="supplierId" reference="supplier">
          <SelectInput optionText="supplierName" required={true} />
        </ReferenceInput>
        <ReferenceInput label="Origin" source="originId" reference="origins">
          <SelectInput optionText="name" required={true} />
        </ReferenceInput>
        <ReferenceInput label="Transport" source="transportId" reference="transport">
          <SelectInput optionText="name" required={true} />
        </ReferenceInput>

        <TextInput source="order" />
      </SimpleForm>
    </Edit>
  );
};



const articleProcessResource = () => <Resource
  name="articleProcess"
  options={{ label: 'Article process', menuGroup: 'Articles'}}
  list={ArticleProcessList}
  create={CreateArticleProcess}
  edit={EditArticleProcess}
  show={ShowArticleProcess} />;

export default articleProcessResource;
