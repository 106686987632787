import {
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  TextField,
  EditButton,
  DateField,
  Resource,
  Filter,
  Show,
  SelectInput,
  Datagrid } from "react-admin";
import { TIER_OPTIONS } from "../consts";

const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
  </Filter>
);

const LcaCategoryList = (props) => {
  return (
    <List {...props} filters={Filters(props)}>
      <div style={{marginTop: '15px'}}>
        <Datagrid rowClick="show">
          <TextField source="name" />
          <TextField source="id" />
          <TextField source="tier" />
          <DateField source="createdAt" label="Creation date" />
          <DateField source="updatedAt" label="Update date" />
          <EditButton />
        </Datagrid>
      </div>
    </List>
  );
};

const ShowLcaCategory = (props) => {
  return (
    <Show {...props}>
      <SimpleForm>
        <TextField source="name" />
        <TextField source="id" />
        <TextField source="tier" />
        <DateField source="createdAt" label="Creation date" />
        <DateField source="updatedAt" label="Update date" />
      </SimpleForm>
    </Show>
  );
};

const CreateLcaCategory = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <SelectInput source="tier" choices={TIER_OPTIONS} />
      </SimpleForm>
    </Create>
  );
};

const EditLcaCategory = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <SelectInput source="tier" choices={TIER_OPTIONS} />
      </SimpleForm>
    </Edit>
  );
};

const lcaCategoryResource = () => <Resource
  name="lcaCategory"
  options={{ label: 'LCA category', menuGroup: 'Bcome'}}
  list={LcaCategoryList}
  create={CreateLcaCategory}
  edit={EditLcaCategory}
  show={ShowLcaCategory} />;

export default lcaCategoryResource;
