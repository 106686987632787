import {
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  TextField,
  EditButton,
  DateField,
  Resource,
  Show,
  Filter,
  Datagrid,
  ReferenceField,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import { ARTICLE_CATEGORY_TYPE } from "../consts";

const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
  </Filter>
);

const ArticleCategoryList = (props) => {
  return (
    <List {...props} filters={Filters(props)}>
      <div style={{marginTop: '15px'}}>
        <Datagrid rowClick="show">
          <TextField source="name" />
          <TextField source="id" />
          <ReferenceField source="group.id" reference="articleGroup" label="Article group">
            <TextField source="name" />
          </ReferenceField>
          <TextField source="type" />
          <DateField source="createdAt" label="Creation date" />
          <DateField source="updatedAt" label="Update date" />
          <EditButton />
        </Datagrid>
      </div>
    </List>
  );
};

const ShowArticleCategory = (props) => {
  return (
    <Show {...props}>
      <SimpleForm>
        <TextField source="name" />
        <TextField source="id" />
        <ReferenceField source="group.id" reference="articleGroup" label="Article group">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="type" />
        <DateField source="createdAt" label="Creation date" />
        <DateField source="updatedAt" label="Update date" />
      </SimpleForm>
    </Show>
  );
};

const CreateArticleCategory = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <ReferenceInput source="group.id" reference="articleGroup" label="Article group">
          <SelectInput source="name" />
        </ReferenceInput>
        <SelectInput source="type" choices={ARTICLE_CATEGORY_TYPE} />
      </SimpleForm>
    </Create>
  );
};

const EditArticleCategory = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <ReferenceInput source="group.id" reference="articleGroup" label="Article group">
          <SelectInput source="name" />
        </ReferenceInput>
        <SelectInput source="type" choices={ARTICLE_CATEGORY_TYPE} />
      </SimpleForm>
    </Edit>
  );
};

const articleCategoryResource = () => <Resource
  name="articleCategory"
  options={{ label: 'Article category', menuGroup: 'Bcome'}}
  list={ArticleCategoryList}
  create={CreateArticleCategory}
  edit={EditArticleCategory}
  show={ShowArticleCategory} />;

export default articleCategoryResource;
