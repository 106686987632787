import {
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  TextField,
  EditButton,
  DateField,
  Resource,
  ReferenceField,
  ReferenceInput,
  Filter,
  SelectInput,
  Show,
  Datagrid } from "react-admin";

import React from "react";
import { scoreChoices } from "../consts";


const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
    <SelectInput source="score" choices={scoreChoices} />

    <ReferenceInput label="Area" source="areaId" reference="evaluationCategory">
        <SelectInput optionText="name"/>
    </ReferenceInput>

    <ReferenceInput label="Group" source="groupId" reference="evaluationGroup">
        <SelectInput optionText="name" />
    </ReferenceInput>

  </Filter>
);

const BestPracticesList = (props) => {
  return (
    <List {...props} filters={Filters(props)}>
      <div style={{marginTop: '15px'}}>
        <Datagrid rowClick="show">
          <TextField source="name" />
          <TextField source="score" />
          <ReferenceField label="Area" source="areaId" reference="evaluationCategory">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField label="Group" source="groupId" reference="evaluationGroup">
            <TextField source="name" />
          </ReferenceField>
          <DateField source="createdAt" label="Creation date" />
          <DateField source="updatedAt" label="Update date" />
          <EditButton />
        </Datagrid>
      </div>
    </List>
  );
};

const ShowBestPractices = (props) => {
  return (
    <Show {...props}>
      <SimpleForm>
        <TextField source="name" />
        <TextField source="score" />

        <ReferenceField label="Area" source="areaId" reference="evaluationCategory">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Group" source="groupId" reference="evaluationGroup">
          <TextField source="name" />
        </ReferenceField>

        <DateField source="createdAt" label="Creation date" />
        <DateField source="updatedAt" label="Update date" />
        <EditButton />
      </SimpleForm>
    </Show>
  );
};

const CreateBestPractices = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="score" />

        <ReferenceInput label="Area" source="areaId" reference="evaluationCategory">
          <SelectInput optionText="name" required={true} />
        </ReferenceInput>
        <ReferenceInput label="Group" source="groupId" reference="evaluationGroup">
          <SelectInput optionText="name" required={true} />
        </ReferenceInput>

      </SimpleForm>
    </Create>
  );
};

const EditBestPractices = (props) => {
    return (
        <Edit {...props}>
          <SimpleForm >
            <TextInput source="name" />
            <TextInput source="score" />

            <ReferenceInput label="Area" source="areaId" reference="evaluationCategory">
              <SelectInput optionText="name" required={true} />
            </ReferenceInput>
            <ReferenceInput label="Group" source="groupId" reference="evaluationGroup">
              <SelectInput optionText="name" required={true} />
            </ReferenceInput>

          </SimpleForm>
        </Edit>
  );
};



const bestPracticesResource = () => <Resource
  name="bestPractices"
  options={{ label: 'Best practices', menuGroup: 'Bcome'}}
  list={BestPracticesList}
  create={CreateBestPractices}
  edit={EditBestPractices}
  show={ShowBestPractices} />;

export default bestPracticesResource;
