import {
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  TextField,
  Show,
  SimpleShowLayout,
  Resource,
  DateField,
  DateInput,
  useNotify,
  Filter, FunctionField, useRefresh, useDataProvider,
  Datagrid,
  BooleanField,
  BooleanInput,
  useEditController,
  useInput,
  NumberInput,
  TopToolbar,
  EditButton,
} from "react-admin";
import { Button } from "@material-ui/core";
import { hostname } from "../providers/api";
import { useTokenOps } from "../components/TokenContext";
import { JsonField, JsonInput } from "react-admin-json-view";
import { JSONValidator } from "../utils/validators/jsonvalidator";
import { useEffect, useState } from "react";
import Toggle from "../components/toggle/toggle";
import { useParams } from 'react-router-dom';

import { v4 as uuidv4 } from 'uuid';

const accountTokenRequest = (tokenOps, accountId) => () => {
  if (tokenOps.rootToken?.data?.accountId === accountId) {
    tokenOps.exitAccountToken();
  } else {
    const request = new Request(hostname + "/auth/switch/" + accountId, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        "authorization": "Bearer " + tokenOps.root.accessToken
      }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(tokens => {
        tokenOps.setAccountToken(tokens);
      });
  };
};

const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
  </Filter>
);

const AccountsList = (props) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const dataProvider = useDataProvider()
  
  const handleVisibility = (e) => {
    e.stopPropagation();
  };

  const updateViewAccount = (id, inactive) => {

    dataProvider.update('accounts', {
      id: id,
      data: { inactive: !inactive },
      previousData: { id: id, inactive: inactive }
    })
      .then((response) => {
        if (response.statusCode && response.statusCode >= 400) {
          notify(`Error: ${response.statusText}`, "warning");
        } else {
          refresh();
          notify("Updated successfully", "success");
        }
      });
  };

  return (
    <List {...props} filters={Filters(props)}>
      <div style={{ marginTop: '15px' }}>
        <Datagrid rowClick="show" >
          <TextField source="name" />
          <TextField source="id" />
          <TextField source="refUsed" label="References used" />
          <FunctionField
            label="References available"
            render={record => record.totalReferences - record.refUsed}
          />
          <TextField source="totalReferences" />
          <TextField source="historicalReference" label="Historical references" />
          <FunctionField
            onClick={handleVisibility}
            label="Account visibility"
            source="inactive"
            render={(item) => (
              <Toggle
                checked={!item.inactive}
                onChange={() => updateViewAccount(item.id, item.inactive)}
                className="ml-2"
              />
            )}
          />
          <TextField source="logoUrl" label="Logo image url" />
          <DateField source="contractStartDate" />
          <BooleanField source="apiIntegration" />
          <TextField source="apiIntegrationKey" label="Api Integration Key" />
          <DateField source="expiration" />
          <DateField source="createdAt" label="Creation date" />

        </Datagrid>
      </div>
    </List>
  )
};

const AccountShowActions = ({ basePath, data }) => {
  const tokenOps = useTokenOps();
  const isWrongAccount = data?.id !== tokenOps.account?.data?.accountId;

  return (
    <TopToolbar>
      <EditButton
        basePath={basePath}
        record={data}
        disabled={isWrongAccount}
        sx={{
          opacity: isWrongAccount ? 0.5 : 1,
          '&.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.26)'
          }
        }}
      />
    </TopToolbar>
  );
};

export const AccountsShow = (props) => {
  const notify = useNotify();
  const [state, setState] = useState('ready');
  const tokenOps = useTokenOps();

  const installPresetViews = () => {
    setState('loading');

    fetch(hostname + '/views/installPresetView', {
      method: 'POST',
      body: JSON.stringify({ "accountId": tokenOps.account?.data.accountId }),
      headers: {
        "Content-Type": "application/json",
        'authorization': 'Bearer ' + tokenOps.account?.accessToken,
      }
    }).then(({ status, statusText }) => {
      setState('done');
      if (status < 400) {
        notify('views installed successfully', 'success');
      } else {
        notify(`Error: ${statusText}`, 'warning')
      }
    })
  };

  const deletePresetViews = () => {
    setState('loading');

    fetch(hostname + '/views/deletePresetView', {
      method: 'POST',
      body: JSON.stringify({ "accountId": tokenOps.account?.data.accountId }),
      headers: {
        "Content-Type": "application/json",
        'authorization': 'Bearer ' + tokenOps.account?.accessToken,
      }
    }).then(({ status, statusText }) => {
      setState('done');
      if (status < 400) {
        notify('views deleted successfully', 'success');
      } else {
        notify(`Error: ${statusText}`, 'warning')
      }
    })
  };

  return (
    <Show {...props} actions={<AccountShowActions />}>
      <SimpleShowLayout>
        <TextField source="name" />
        <TextField source="id" />
        <DateField source="contractStartDate" />
        <DateField source="expiration" />
        <TextField source="refUsed" label="References used" />
        <FunctionField
          label="References available"
          render={record => record.totalReferences - record.refUsed}
        />
        <TextField source="totalReferences" />
        <TextField source="historicalReference" label="Historical references" />
        <TextField source="eCommerceDomain" />
        <BooleanField source="apiIntegration" />
        <TextField source="apiIntegrationKey" label="Api Integration Key" />
        <DateField source="createdAt" label="Creation date" />
        {tokenOps.account?.data.accountId === props.id &&
          (<>
            <Button color="primary"
              variant="contained"
              style={{ marginTop: '10px', marginBottom: '10px' }}
              onClick={installPresetViews}
              disabled={state === 'state'}
              component="label">
              Install preset views
            </Button>
            <Button color="primary"
              variant="contained"
              style={{ color: 'white', backgroundColor: 'red', margin: '20px' }}
              onClick={deletePresetViews}
              disabled={state === 'state'}
              component="label">
              Delete preset views
            </Button>
          </>
          )
        }
        <JsonField
          source="eCommerceCustomization"
          addLabel={true}
          jsonString={false} // Set to true if the value is a string, default: false
          reactJsonOptions={{
            // Props passed to react-json-view
            name: null,
            collapsed: true,
            enableClipboard: false,
            displayDataTypes: true,
          }}
        />
        <TextField source="eCommerceDomain" />

        {!tokenOps.isSwitchAccount && <Button type="primary" onClick={accountTokenRequest(tokenOps, props.id)}>Switch Account</Button>}
        {tokenOps.isSwitchAccount && <Button type="primary" onClick={tokenOps.exitAccountToken}>Logout</Button>}

      </SimpleShowLayout>
    </Show>
  );
}

const CreateAccount = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" required={true} />
        <TextInput source="logoUrl" required={false} />
        <BooleanInput source="apiIntegration" />
        <DateInput source="contractStartDate" defaultValue={getStartOfToday()} />
        <NumberInput source="totalReferences" defaultValue={0} />
        <TextInput source="eCommerceDomain" />
        <TextInput source="refAvailable" style={{ visibility: 'hidden' }} defaultValue={0} />
      </SimpleForm>
    </Create>
  )
};

const getStartOfToday = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set the time to midnight
  return today;
};

const CustomTextFieldWithButton = ({ input, source, label, ...rest }) => {
  const tokenOps = useTokenOps();
  const { id } = useParams();
  const notify = useNotify();
  const refresh = useRefresh();
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    const data = JSON.stringify({ refUsed: 0, contractStartDate: getStartOfToday() });
    fetch(hostname + "/accounts/" + id, {
      method: "PATCH",
      body: data,
      headers: {
        "Content-type": "application/json",
        authorization: "Bearer " + tokenOps.account?.accessToken,
      },
    }).then((response) => {
      if (response.status < 400) {
        refresh();
        notify("Updated successfully", "success");
      } else {
        notify(`Error: ${response.statusText}`, "warning");
      }
    })
      .finally(() => setLoading(false));
  };

  return (
    <div>
      <label className="reference-used" htmlFor={source}>
        <span>{label}</span>
      </label>
      <div className="account-reset-references">
        <TextInput type="number" {...input} source={source} {...rest} label="" />
        <Button
          variant="contained"
          style={{ marginTop: '10px', marginBottom: '10px' }}
          component="label"
          onClick={handleClick}
          disabled={loading}
        >
          {loading ? 'Loading...' : 'Reset references'}
        </Button>
      </div>

    </div>
  );
};

const CustomTextInput = ({ label, source, value, disabled }) => {
  const {
    input: { name, onChange, ...restInput },
    meta: { touched, error },
  } = useInput({ source });

  // Update the field value manually when the prop changes
  useEffect(() => {
    onChange(value);
  }, [value, onChange]);

  return (
    <TextInput
      label={label}
      name={name}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled}
      error={touched && !!error}
      helperText={touched && error}
      style={{ width: 330 }}
      {...restInput}
    />
  );
};

const EditAccount = (props) => {
  const [apiKey, setApiKey] = useState(null);
  const [loading, setLoading] = useState(false);
  const { record, isLoading } = useEditController(props);
  const [apiIntegration, setApiIntegration] = useState(record.apiIntegration);

  if (isLoading) return <div>Loading...</div>;

  const handleApiKey = () => {
    setLoading(true);
    if (!record.apiIntegrationKey) {
      setApiKey(uuidv4());
    }
    setLoading(false);
  }

  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="logoUrl" required={false} />
        <DateInput source="contractStartDate" />
        <DateInput source="expiration" />
        <TextInput source="totalReferences" defaultValue={0} />
        <TextField source="historicalReference" label="Historical references" />
        <CustomTextFieldWithButton source="refUsed" label="References used" />
        <FunctionField
          label="References available"
          render={record => record.totalReferences - record.refUsed}
        />
        <JsonInput
          source="eCommerceCustomization"
          validate={[JSONValidator]}
          jsonString={false}
          reactJsonOptions={{
            name: null,
            collapsed: true,
            enableClipboard: false,
            displayDataTypes: true,
          }}
        />
        <TextInput source="eCommerceDomain" required={false} />
        <BooleanInput source="apiIntegration" onChange={() => setApiIntegration(!apiIntegration)} />
        {
          record?.apiIntegrationKey
            ? <TextInput style={{ width: 330 }} source="apiIntegrationKey" disabled label="Api Integration Key" />
            : <>
              <CustomTextInput
                source="apiIntegrationKey"
                label="API Integration Key"
                value={apiKey}
                disabled
              />
              <div className="account-reset-references">
                <Button
                  variant="contained"
                  style={{ marginTop: '10px', marginBottom: '10px' }}
                  component="label"
                  onClick={handleApiKey}
                  disabled={loading || !apiIntegration}
                >
                  {loading ? 'Loading...' : 'Generate API Key'}
                </Button>
              </div>
            </>
        }
      </SimpleForm>
    </Edit>
  );
};

const accountsResource = () => <Resource
  name="accounts"
  options={{ label: 'Accounts', menuGroup: 'Accounts' }}
  list={AccountsList}
  create={CreateAccount}
  edit={EditAccount}
  show={AccountsShow} />;

export default accountsResource;
