import React from "react";
import {
  List,
  Show,
  SimpleShowLayout,
  TextField,
  TextInput,
  Filter,
  EditButton,
  SimpleForm,
  Edit,
  DateField,
  Resource,
  ReferenceField,
  SelectInput,
  Datagrid } from "react-admin";


const groupDropdownChoices = [
  { id: 'global', name: 'Global' },
  { id: 'stages', name: 'Stages' },
  { id: 'indicators', name: 'Indicators' },
  { id: 'custom', name: 'Custom' }
]

const productChoices = [
  { id: 'lca', name: 'lca' },
  { id: 'sustainabilityindex', name: 'Sustainability Index' },
  { id: 'smartlabels', name: 'Smartlabels' },
  { id: 'benchmark', name: 'Benchmark' }
]

const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
    <SelectInput label="Group"  source="groupDropdown" choices={groupDropdownChoices} />
    <SelectInput label="Product"  source="product" choices={productChoices} />
  </Filter>
);

const ViewsList = (props) => {
   return (
    <List {...props} filters={Filters(props)}>
      <div style={{marginTop: '15px'}}>
        <Datagrid rowClick="show">
          <TextField source="name" />
          <TextField source="id" />
          <TextField source="groupDropdown" label="Group dropdown" />
          <TextField source="schemaName" label="Schema name" />
          <TextField source="product" />
          <TextField source="columns" />
          <DateField source="createdAt" label="Creation date" />
          <DateField source="updatedAt" label="Updated date" />
          <ReferenceField label="Account" source="accountId" reference="accounts">
            <TextField source="name" />
          </ReferenceField>
          <EditButton />
        </Datagrid>
      </div>
    </List>
  );
};

const ShowViews = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="name" />
        <TextField source="id" />
        <TextField source="groupDropdown" label="Group dropdown" />
        <TextField source="schemaName" label="Schema name" />
        <TextField source="product" />
        <TextField source="columns" />
        <DateField source="createdAt" label="Creation date" />
        <DateField source="updatedAt" label="Updated date" />
        <ReferenceField label="Account" source="accountId" reference="accounts">
          <TextField source="name" />
        </ReferenceField>
      </SimpleShowLayout>
    </Show>
  );
};

const EditView = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" />
      </SimpleForm>
    </Edit>
  );
};


const viewsResource = () => <Resource
  name="views"
  options={{ label: 'Views', menuGroup: 'Projects'}}
  list={ViewsList}
  edit={EditView}
  show={ShowViews} />;

export default viewsResource;
