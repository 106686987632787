import {
    Create,
    Edit,
    EditButton,
    List,
    Resource,
    Show,
    SimpleForm,
    SelectInput,
    TextField,
    TextInput,
    Datagrid
} from "react-admin";
import React from "react";

import {Filters} from "./BasicResource";
import { FEATURES_OPTIONS } from "../consts";

const FeaturesList = (props) => {
    return (
        <List {...props} filters={Filters(props)} sort={{ field: 'area', order: 'DESC' }}>
            <div style={{marginTop: '15px'}}>
                <Datagrid rowClick="show">
                    <TextField source="name" label="Features" />
                    <TextField source="area" label="Area" />
                    <TextField source="description" label="Definition" />
                    <TextField source="key" label="Key Name" />
                    <EditButton/>
                </Datagrid>
            </div>
        </List>
    );
};

const CreateFeatures = (props) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <SelectInput source="area" choices={FEATURES_OPTIONS} label="Area" />
                <TextInput source="name" label="Features" required={true}/>
                <TextInput source="key" label="Key name" required={true}/>
                <TextInput source="description" label="Description" required={true} />
            </SimpleForm>
        </Create>
    );
};

const EditFeatures = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <SelectInput source="area" choices={FEATURES_OPTIONS} label="Area" />
                <TextInput source="name" label="Features" required={true}/>
                <TextInput source="key" label="Key name" required={true}/>
                <TextInput source="description" label="Description" required={true} />
            </SimpleForm>
        </Edit>
    );
};

const ShowFeatures = (props) => {
    return (
        <Show {...props}>
            <SimpleForm>
                <TextField source="area" label="Area" />
                <TextField source="name" label="Features" />
                <TextField source="key" label="Key Name" />
                <TextField source="description" label="Definition" />
            </SimpleForm>
        </Show>
    );
};

const featuresResource = () => <Resource
    name="features"
    options={{label: 'Features', menuGroup: 'Accounts'}}
    list={FeaturesList}
    edit={EditFeatures}
    create={CreateFeatures}
    show={ShowFeatures}/>;

export default featuresResource;

