import {
    List,
    Create,
    Edit,
    SimpleForm,
    TextInput,
    TextField,
    EditButton,
    DateField,
    Resource,
    Show,
    Filter,
    Datagrid
  } from "react-admin";

  const Filters = (props) => (
    <Filter {...props}>
      <TextInput label="Search" source="name" alwaysOn />
    </Filter>
  );

  const HazardousSusbtancesList = (props) => {
    return (
      <List {...props} filters={Filters(props)}>
        <div style={{marginTop: '15px'}}>
          <Datagrid rowClick="show">
            <TextField source="name" />
            <TextField source="id" />
            <TextField source="shortName" />
            <TextField source="ecNumber" label="EC Number" />
            <TextField source="casNumber" label="CAS Number" />
            <DateField source="createdAt" label="Creation date" />
            <DateField source="updatedAt" label="Update date" />
            <EditButton />
          </Datagrid>
        </div>
      </List>
    );
  };

  const ShowHazardousSusbtances = (props) => {
    return (
      <Show {...props}>
        <SimpleForm>
            <TextField source="name" />
            <TextField source="id" />
            <TextField source="shortName" />
            <TextField source="ecNumber" label="EC Number" />
            <TextField source="casNumber" label="CAS Number" />
            <DateField source="createdAt" label="Creation date" />
            <DateField source="updatedAt" label="Update date" />
        </SimpleForm>
      </Show>
    );
  };

  const CreateHazardousSusbtances = (props) => {
    return (
      <Create {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="shortName" />
            <TextInput source="ecNumber" label="EC Number" />
            <TextInput source="casNumber" label="CAS Number" />
        </SimpleForm>
      </Create>
    );
  };

  const EditHazardousSusbtances = (props) => {
    return (
      <Edit {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="shortName" />
            <TextInput source="ecNumber" label="EC Number" />
            <TextInput source="casNumber" label="CAS Number" />
        </SimpleForm>
      </Edit>
    );
  };

  const hazardousSusbtancesResource = () => <Resource
    name="hazardousSubstances"
    options={{ label: 'Hazardous susbtances', menuGroup: 'Bcome'}}
    list={HazardousSusbtancesList}
    create={CreateHazardousSusbtances}
    edit={EditHazardousSusbtances}
    show={ShowHazardousSusbtances} />;

  export default hazardousSusbtancesResource;
