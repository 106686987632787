import {
  List,
  SimpleForm,
  TextInput,
  TextField,
  DateField,
  Resource,
  Filter,
  Show,
  Datagrid,
  useRecordContext,
} from "react-admin";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { useUpdateDom } from "../hooks/useUpdateDom";
import { Tooltip } from "@material-ui/core";

const StatusIcon = ({ record = {} }) => {
  const { status } = record;
  let icon = null;
  switch (status) {
    case "UPLOADED":
      icon = <CloudUploadIcon htmlColor="orange" />;
      break
    case "PROCESSING":
      icon = <HourglassEmptyIcon htmlColor="orange" />;
      break
    case "SUCCESS":
      icon = <CheckCircleOutlineIcon htmlColor="green" />;
      break
    case "FAILED":
      icon = <ErrorOutlineIcon htmlColor="red" />;
      break
    default:
      icon = null;
  }

  return <Tooltip title={status}>{ icon }</Tooltip>
};

const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
  </Filter>
);

const TypeField = ({ source }) => {
  const record = useRecordContext();
  const value = record?.[source];

  // Apply conditional logic
  let displayValue;
  if (value === 'SIMULATION') {
    displayValue = 'CHECK';
  } else {
    displayValue = value;
  }

  return <span>{displayValue}</span>;
};

const UploadStatusList = (props) => {
  useUpdateDom();
  return (
    <List {...props} filters={Filters(props)} sort={{ field: 'lastStatusDate', order: 'DESC' }}>
      <div style={{ marginTop: "15px" }}>
        <Datagrid rowClick="show">
          <TextField source="filename" />
          <TextField source="tab" label="Upload" />
          <TypeField source="type" />
          <TextField source="username" label="Account" />
          <TextField source="projectname" label="Project name" />
          <StatusIcon label="Status" />
          <DateField showTime source="uploadDate" label="Upload date" />
          <DateField
            showTime
            source="lastStatusDate"
            label="Last status date"
          />
        </Datagrid>
      </div>
    </List>
  );
};

const ShowUploadStatus = (props) => {
  useUpdateDom();

  return (
    <Show {...props}>
      <SimpleForm>
        <TextField source="filename" />
        <TextField source="tab" label="Upload" />
        <TypeField source="type" />
        <TextField source="username" label="Account" />
        <TextField source="projectname" label="Project name" />
        <TextField source="status" />
        <StatusIcon label="Status" />
        <TextField source="stacktrace" />
        <DateField showTime source="uploadDate" label="Upload date" />
        <DateField showTime source="lastStatusDate" label="Last status date" />
      </SimpleForm>
    </Show>
  );
};

const uploadStatusResource = () => (
  <Resource
    name="uploadHistory"
    options={{ label: "Traceability status", menuGroup: "Upload Status" }}
    list={UploadStatusList}
    show={ShowUploadStatus}
  />
);

export default uploadStatusResource;
