import {
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  TextField,
  EditButton,
  DateField,
  Resource,
  Show,
  ReferenceField,
  SelectInput,
  Filter,
  ReferenceInput,
Datagrid } from "react-admin";


const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="supplier.supplierName" alwaysOn />
    <ReferenceInput label="Supplier" source="supplierId" reference="supplier">
        <SelectInput optionText="supplierName" />
    </ReferenceInput>
    <ReferenceInput label="Certificates" source="certificateId" reference="certificates">
        <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput label="Account" source="accountId" reference="accounts">
        <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>

);

const SupplierCertificateList = (props) => {
  return (
    <List {...props} filters={Filters(props)}>
      <div style={{marginTop: '15px'}}>
        <Datagrid rowClick="show">
          <TextField source="id" />
          <ReferenceField label="Supplier" source="supplierId" reference="supplier">
            <TextField source="supplierName" />
          </ReferenceField>
          <ReferenceField label="Certificates" source="certificateId" reference="certificates">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField label="Account" source="accountId" reference="accounts">
            <TextField source="name" />
          </ReferenceField>
          <TextField source="status" />
          <DateField source="createdAt" label="Creation date" />
          <DateField source="updatedAt" label="Update date" />
          <EditButton />
        </Datagrid>
      </div>
    </List>
  );
};

const ShowSupplierCertificate = (props) => {
  return (
    <Show {...props}>
      <SimpleForm>
        <TextField source="id" />
        <ReferenceField label="Supplier" source="supplierId" reference="supplier">
          <TextField source="supplierName" />
        </ReferenceField>
        <ReferenceField label="Certificates" source="certificateId" reference="certificates">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Account" source="accountId" reference="accounts">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="status" />
        <DateField source="createdAt" label="Creation date" />
        <DateField source="updatedAt" label="Update date" />
      </SimpleForm>
    </Show>
  );
};

const CreateSupplierCertificate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="id" />
        <ReferenceInput label="Supplier" source="supplierId" reference="supplier">
          <SelectInput optionText="supplierName" required={true} />
        </ReferenceInput>
        <ReferenceInput label="Certificates" source="certificateId" reference="certificates">
          <SelectInput optionText="name" required={true} />
        </ReferenceInput>
        <TextInput source="status" />
      </SimpleForm>
    </Create>
  );
};

const EditSupplierCertificate = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
      <TextInput source="id" />
        <ReferenceInput label="Supplier" source="supplierId" reference="supplier">
          <SelectInput optionText="supplierName" required={true} />
        </ReferenceInput>
        <ReferenceInput label="Certificates" source="certificateId" reference="certificates">
          <SelectInput optionText="name" required={true} />
        </ReferenceInput>
        <TextInput source="status" />
      </SimpleForm>
    </Edit>
  );
};


const supplierCertificateResource = () => <Resource
  name="supplierCertificate"
  options={{ label: 'Supplier certificates', menuGroup: 'Suppliers'}}
  list={SupplierCertificateList}
  create={CreateSupplierCertificate}
  edit={EditSupplierCertificate}
  show={ShowSupplierCertificate} />;

export default supplierCertificateResource;
