import {
  List,
  SimpleForm,
  TextInput,
  TextField,
  DateField,
  Resource,
  Filter,
  Show,
  Datagrid,
} from "react-admin";

import React from "react";

const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="packaging_name" alwaysOn />
  </Filter>
);

const DigitalizationDataList = (props) => {
  return (
    <List {...props} filters={Filters(props)}>
      <div style={{ marginTop: "15px" }}>
        <Datagrid rowClick="show">
          <TextField source="packaging_name" label="Description" />
          <TextField source="project" />
          <TextField
            source="packaging_process_recycled_or_reused_share"
            label="Recycled content"
          />
          <TextField
            source="packaging_process_reusability"
            label="Reusability"
          />
          <TextField
            source="packaging_process_recyclabilityFR"
            label="Recyclability"
          />
          <TextField
            source="packaging_hazardous_substances"
            label="Hazardous substances"
          />

          <DateField source="createdAt" label="Creation date" />
          <DateField source="updatedAt" label="Update date" />
        </Datagrid>
      </div>
    </List>
  );
};

const ShowDigitalizationData = (props) => {
  return (
    <Show {...props}>
      <SimpleForm>
        <TextField source="packaging_name" label="Description" />
        <TextField source="project" />
        <TextField
          source="packaging_process_recycled_or_reused_share"
          label="Recycled content"
        />
        <TextField source="packaging_process_reusability" label="Reusability" />
        <TextField
          source="packaging_process_recyclabilityFR"
          label="Recyclability"
        />
        <TextField
          source="packaging_hazardous_substances"
          label="Hazardous substances"
        />

        <DateField source="createdAt" label="Creation date" />
        <DateField source="updatedAt" label="Update date" />
      </SimpleForm>
    </Show>
  );
};

const digitalizationDataAccountResource = () => (
  <Resource
    name="accountAgecSummary"
    options={{ label: "Digitization data", menuGroup: "Accounts" }}
    list={DigitalizationDataList}
    show={ShowDigitalizationData}
  />
);

export default digitalizationDataAccountResource;
