import { Resource, Filter } from "react-admin";

export const Filters = (props, children) => (
  <Filter {...props}>{children}</Filter>
);

const BasicResource = ({ name, list, create, edit, show }) => (
  <Resource name={name} list={list} create={create} edit={edit} show={show} />
);

export default BasicResource;
