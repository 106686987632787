import { useEffect } from "react";
import { useRefresh } from "react-admin";

export const useUpdateDom = () => {
  const refresh = useRefresh();

  useEffect(() => {
    const interval = setInterval(() => {
      // This will trigger the List to refresh its data from the server
      refresh();
    }, 300000); // 300000 ms = 5 minutes

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [refresh]);
};
