import Ajv from 'ajv';
const ajv = new Ajv();

const arrangementSchema = {
  type:"object",
    properties:{
    "displayed":{type: "boolean"},
    "enabled":{type: "boolean"},
    "order":{type: "integer"}
  },
  required:["displayed","enabled","order"]
};
const tabSectionSchema = {
  type:"object",
  properties:{
    "enabled":{type: "boolean"},
    "order":{type: "integer"}
  },
  required:["enabled","order"]
};
const JSONschema = {
    type:"object",
    properties:{
        "mode":{type: "string"},
        "elementSelector":{type: "string"},
        "customCss":{type: "string"},
        "customParentCss":{type: "string"},
        "sustainability":{
            type:"object",
            properties:{
              "arrangement": arrangementSchema,
            },
        },
        "traceability":{
            type:"object",
            properties:{
              "arrangement": arrangementSchema,
             },
        },
        "environment":{
            type:"object",
            properties:{
                "arrangement": arrangementSchema,
                "equivalences": tabSectionSchema,
                "main": tabSectionSchema,
                "savings": tabSectionSchema,
            }
        }
    },
    required:["customCss", "customParentCss","sustainability","traceability","environment"]
}





export const JSONValidator = (value, allValues) => {

    const validate = ajv.compile(JSONschema);
    const valid = validate(value);


    return valid ? undefined: "Please enter a valid JSON format";
}
