import {
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  TextField,
  EditButton,
  DateField,
  Resource,
  Show,
  SelectInput,
  Filter,
  ReferenceInput,
  Datagrid } from "react-admin";


const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
    <TextInput label="Reference Year" source="referenceYear" />
    <TextInput label="Geography" source="geography" />
    <TextInput label="Primary Source" source="primarySource" />
  </Filter>
);

const CertificatesList = (props) => {
  return (
    <List {...props} filters={Filters(props)}>
      <div style={{marginTop: '15px'}}>
        <Datagrid rowClick="show">
          <TextField source="name" />
          <TextField source="id" />
          <TextField source="description" />
          <TextField source="referenceYear" />
          <TextField source="geography" />
          <TextField source="primarySource" />
          <TextField source="modelingSheet" />
          <TextField source="status" />
          <DateField source="createdAt" label="Creation date" />
          <DateField source="updatedAt" label="Update date" />
          <EditButton />
        </Datagrid>
      </div>
    </List>
  );
};

const ShowCertificates = (props) => {
  return (
    <Show {...props}>
      <SimpleForm>
        <TextField source="name" />
        <TextField source="id" />
        <TextField source="description" />
        <TextField source="referenceYear" />
        <TextField source="geography" />
        <TextField source="primarySource" />
        <TextField source="modelingSheet" />
        <TextField source="status" />
        <DateField source="createdAt" label="Creation date" />
        <DateField source="updatedAt" label="Update date" />
      </SimpleForm>
    </Show>
  );
};

const CreateCertificates = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <ReferenceInput label="Category" source="categoryId" reference="evaluationCategory">
          <SelectInput source="name" />
        </ReferenceInput>
        <ReferenceInput label="Group" source="groupId" reference="evaluationGroup">
          <SelectInput source="name" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

const EditCertificates = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <ReferenceInput label="Category" source="categoryId" reference="evaluationCategory">
          <SelectInput source="name" />
        </ReferenceInput>
        <ReferenceInput label="Group" source="groupId" reference="evaluationGroup">
          <SelectInput source="name" />
        </ReferenceInput>
        <TextInput source="status" />
      </SimpleForm>
    </Edit>
  );
};


const certificatesResource = () => <Resource
  name="certificates"
  options={{ label: 'Certificates', menuGroup: 'Bcome'}}
  list={CertificatesList}
  create={CreateCertificates}
  edit={EditCertificates}
  show={ShowCertificates} />;

export default certificatesResource;
